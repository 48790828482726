@keyframes slider_gradient {
  0% {
    background-position: 0% 50%;
    background-attachment: local;
    filter: brightness(1);
  }
  10% {
    background-attachment: fixed;
    filter: brightness(0.5);
  }
  20% {
    background-attachment: local;
    filter: brightness(1);
  }
  30% {
    background-attachment: fixed;
    filter: brightness(0.2);
  }
  40% {
    background-attachment: local;
    filter: brightness(1);
  }
  50% {
    background-position: 100% 50%;
    background-attachment: fixed;
    filter: brightness(0.2);
  }
  60% {
    background-attachment: local;
    filter: brightness(1);
  }
  70% {
    background-attachment: fixed;
    filter: brightness(0.2);
  }
  80% {
    background-attachment: local;
    filter: brightness(1);
  }
  90% {
    background-attachment: fixed;
    filter: brightness(0.2);
  }
  100% {
    background-position: 0% 50%;
    background-attachment: local;
    filter: brightness(1);
  }
}

@keyframes slider_logo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slider_img {
  0% {
    background-position-y: center;
  }
  50% {
    background-position-y: top;
  }
  100% {
    background-position-y: center;
  }
}

@keyframes arrow_scala {
  0% {
    transform: rotate(90deg) scale(1);
  }
  50% {
    transform: rotate(90deg) scale(1.3);
  }
  100% {
    transform: rotate(90deg) scale(1);
  }
}

@keyframes hamburgerSpan1 {
  0% {
    transform: translate(0,0);
  }
  100% {
    margin-top: -3px;
    transform: rotate(-45deg) translate(-10px, 10px);
  }
}

@keyframes hamburgerSpan2 {
  0% {
    transform: translate(0,0) scale(1);
  }
  100% {
    margin-top: -3px;
    opacity: 0;
    width: 3px;
    border-radius: 50%;
  }
}

@keyframes hamburgerSpan3 {
  0% {
    transform: translate(0,0);
  }
  100% {
    margin-top: -3px;
    transform: rotate(45deg) translate(-3.5px, -3.5px);
  }
}

@keyframes hamburgerSpan1_close {
  0% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}

@keyframes hamburgerSpan2_close {
  0% {
    transform: translate(0, 40px) scale(0);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes hamburgerSpan3_close {
  0% {
    transform: rotate(45deg) translate(-3.5px, -3.5px);
  }
  100% {
    transform: rotate(0deg) translate(0, 0);
  }
}

@keyframes opacityLeft {
  0% {
    opacity: 0;
    margin-left: 200px;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes opacityRight {
  0% {
    opacity: 0;
    margin-right: 200px;
  }
  100% {
    opacity: 1;
    margin-right: 0;
  }
}

@keyframes opacityTop {
  0% {
    opacity: 0;
    height: 0vh;
  }
  100% {
    opacity: 1;
    height: 100vh;
  }
}

@keyframes opacityBackTop {
  0% {
    opacity: 0;
    height: 100vh;
  }
  100% {
    opacity: 1;
    height: 0;
  }
}

@keyframes openLeft {
  from {
    opacity: 0;
    margin-right: -110%;
  }
  to {
    opacity: 1;
    margin-right: fill;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fromTop {
  from {
    opacity: 0;
    top: -100%;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes fromDown {
  from {
    opacity: 1;
    top: 0;
  }
  to {
    opacity: 0;
    top: -100%;
  }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
