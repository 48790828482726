@import "colors";
@import "mixins";

.sega__productsTab.background-none {
    background: transparent !important;
    border: none;
    box-shadow: none;

    .sega__productsTab_panel {
        background: transparent;
        border: none;
        box-shadow: none;

        &_boxes {

            &_text {

                h3 {
                    margin-left: 0;
                    white-space: normal;
                }
            }
        }
    }
}

.sega__productsTab.background-gray {
    background: $sega-grey !important;

    .sega__productsTab_panel {
        background: $sega-grey !important;
        border-top: 2.5px solid $sega-white;
        border-bottom: 2.5px solid $sega-white;

        &_boxes {

            &_text {

                h3 {
                    margin-left: 0;
                    white-space: normal;
                }
            }
        }
    }
}

.sega__productsTab.background-white {
    background: $sega-white !important;

    .sega__productsTab_panel {
        background: $sega-white !important;
        border-top: 2.5px solid $sega-grey;
        border-bottom: 2.5px solid $sega-grey;

        &_boxes {

            &_text {

                h3 {
                    margin-left: 0;
                    text-align: center;
                    white-space: normal;
                }
            }
        }
    }
}

.sega__articles.background-white {
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
    background: $sega-white;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-top: 2.5px solid $sega-grey;
    border-bottom: 2.5px solid $sega-grey;
    padding: 20px 0 20px 0;

    @include repetitionCommands;
}

.sega__articles.background-gray {
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
    background: $sega-grey;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-top: 2.5px solid $sega-white;
    border-bottom: 2.5px solid $sega-white;
    padding: 20px 0 20px 0;

    @include repetitionCommands;
}

.sega__articles.background-none {
    background: transparent;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    padding: 20px 0 20px 0;

    @include repetitionCommands;
}

.article-image.background-gray {
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
    background: $sega-grey;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-top: 2.5px solid $sega-white;
    border-bottom: 2.5px solid $sega-white;
    padding: 20px 0 20px 0;

    .marginTop {
        display: none;
    }
}

.article-image.background-white {
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
    background: $sega-white;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-top: 2.5px solid $sega-grey;
    border-bottom: 2.5px solid $sega-grey;
    padding: 20px 0 20px 0;

    .marginTop {
        display: none;
    }
}

.article-image {
    margin: 30px auto 20px auto;

    img {
        width: 100%;
        max-width: 1010px;
        padding: 0 15px;
    }
}

.type-center {
    text-align: center;
    justify-content: center;
    align-content: center;
}

.type-width_100 {
    width: 100% !important;

    img {
        width: 100% !important;
        max-width: none !important;
        padding: 0;
    }
}

.enable-paddings {
    padding: 50px 0 !important;

    & > * {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

.disable-paddings {
    padding: 0 !important;
}

.background-none {
    background: transparent !important;
    border: none;
    box-shadow: none;
    max-width: 100%;
}

.background-gray {
    background: $sega-grey !important;
    max-width: 100%;
}

.background-white {
    background: $sega-white !important;
    max-width: 100%;
}

.background-blue {
    background: #00337e!important;
    max-width: 100%;
}

.background-blue_ws {
    background: #00337e!important;
    padding: 20px 20px;
    margin: 20px auto;
    max-width: 100%
}