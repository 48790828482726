.sega-account__content-inner {
  padding-top: 25px;
  width: 100%;

  .info {
    display: block;
    font-style: italic;

    &.red {
      color: $sega-red;
      font-weight: 700;
    }
  }

  .button-blue {
    display: inline-block;
    margin-top: 20px;
    padding: 3px 35px;
  }

  &.account-settings {
    .success-message,
    .error-message {
      border-radius: 5px;
      font-weight: 700;
      font-size: 15px;
      padding: 10px 10px;
      text-align: center;
    }

    .error-message {
      border: 1px solid $sega-red;
      color: $sega-red;
    }

    .success-message {
      border: 1px solid $sega-green;
      color: $sega-green;
    }

    form + h2 {
      margin-top: 50px;
    }

    h2 {
      font-size: 20px;
      margin: 15px 0;
      text-align: left;
      text-transform: uppercase;

      & + .sega__enquiry_container_box_email {
        margin-top: 15px;
      }
    }

    .sega__enquiry_container_subtitle {
      font-weight: 400;
      font-size: 20px;
      font-family: 'futura-pt', sans-serif;
      margin-top: 50px;

      & + .sega__enquiry_container_box_email {
        margin-top: 10px;
      }
    }

    .users-linked-wrapper {
      h3 {
        font-weight: 400;
        font-size: 20px;
        font-family: 'futura-pt', sans-serif;
        margin-top: 50px;
      }

      .single-user-linked{
        background: $sega-white;
        border: 1px solid $sega-dark-grey;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        display: block;
        font-size: 20px;
        margin: 15px 0;
        padding: 10px 15px;
        width: 100%;

        .link {
          font-size: 16px;
        }
      }
    }

    .change-password-form {
      .sega__enquiry_container_subtitle {
        font-weight: 700;
        margin-bottom: 5px;
      }

      .password-wrapper {
        .input-box {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;

          &.times {
            &:before,
            &:after {
              background: $sega-red;
              content: '';
              height: 2px;
              right: 7px;
              position: absolute;
              top: 26px;
              transition: all 0.25s ease-in-out;
              width: 16px;
            }

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }

          &.check {
            &:before {
              border: solid $sega-green;
              border-width: 0 0 2px 2px;
              content: '';
              right: 9px;
              height: 7px;
              position: absolute;
              top: 23px;
              transform: rotate(-50deg);
              width: 13px;
            }
          }
        }

        .label {
          font-size: 20px;
          flex-shrink: 0;
          margin: 15px 0;
          width: 160px;
        }

        input {
          border-radius: 5px;
          border: 1px solid $sega-dark-grey;
          box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
          display: block;
          height: 37px;
          margin-left: auto;
          padding: 0 30px 0 10px;
          width: calc(100% - 160px);
        }

        .show-password-info {
          display: inline-block;
          font-style: italic;
          margin-left: auto;
          text-decoration: underline;
        }
      }

      .password-requirements {
        h3 {
          font-family: 'futura-pt', sans-serif;
          font-weight: 400;
        }

        ul li {
          font-style: italic;
          margin: 3px 0;
          padding-left: 20px;
          position: relative;

          &.times {
            &:before,
            &:after {
              background: $sega-red;
              content: '';
              height: 2px;
              left: 0;
              position: absolute;
              top: 7px;
              transition: all 0.25s ease-in-out;
              width: 16px;
            }

            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }

          &.check {
            &:before {
              border: solid $sega-green;
              border-width: 0 0 2px 2px;
              content: '';
              left: 0;
              height: 7px;
              position: absolute;
              top: 3px;
              transform: rotate(-50deg);
              width: 13px;
            }
          }
        }
      }

      .button-red {
        margin-top: 20px;
      }
    }
  }

  &.manuals {
    .search-bar {
      align-items: center;
      background: $sega-white;
      border-radius: 5px;
      box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
      display: flex;
      margin-bottom: 30px;
      margin-top: 20px;
      padding: 20px;
      width: 100%;

      &-input {
        display: block;
        max-width: 440px;
        position: relative;
        width: calc(100% - 170px);

        img {
          display: block;
          height: auto;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          width: 24px;
        }
      }

      #search-field {
        border: 1px solid $sega-dark-grey;
        padding: 10px 40px 10px 30px;
        width: 100%;
      }

      .select-single {
        border: 1px solid $sega-dark-grey;
        border-radius: 5px;
        box-shadow: none;
        margin-left: auto;
        width: 150px;

        .select2.select2-container {
          box-shadow: none;
        }
      }
    }

    .alphabet {
      display: flex;

      .alphabet-char {
        align-items: center;
        border-right: 1px solid $sega-blue;
        display: flex;
        flex: 1;
        justify-content: center;

        &:hover {
          color: $sega-red
        }

        &.active {
          color: $sega-red;
          font-weight: 700;
        }
      }
    }

    .no-results {
      font-size: 18px;
      font-weight: 700;
      margin-top: 30px;
      padding-left: 5px;
    }

    .single-product-row {
      align-items: flex-start;
      display: flex;
      margin: 30px 0;

      .image-wrapper {
        background: $sega-white;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
        display: block;
        height: 120px;
        width: 120px;

        img {
          display: block;
          height: 100%;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
      }

      .text-wrapper {
        padding-left: 30px;
        width: calc(100% - 120px);

        .title {
          font-size: 40px;
        }

        .go-to-game {
          border: 1px solid $sega-red;
          border-radius: 20px;
          color: $sega-red;
          font-weight: 700;
          margin-left: auto;
          padding: 3px 10px;
          text-transform: uppercase;
          transition: all 0.25s ease-in-out;

          span {
            font-size: 25px;
            line-height: 0;
            position: relative;
            top: 0;
          }

          &:hover {
            background: $sega-red;
            color: $sega-white
          }
        }

        &-top {
          align-items: center;
          border-bottom: 1px solid $sega-blue;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          padding-bottom: 10px;
        }

        .view-manuals-label {
          text-transform: uppercase;

          span {
            font-size: 30px;
            line-height: 0;
            position: relative;
            top: 1px;
          }

          &:hover {
            color: $sega-red
          }
        }

        .files-sections {
          display: block;
          padding-left: 45px;

          &.is-hidden {
            display: none;
          }

          &.marketing {
            padding-left: 0;

            .files-section > a {
              align-items: center;
              display: flex;
              padding-right: 15px;

              .icon {
                height: 24px;
                margin-left: auto;
                width: 24px;
              }
            }
          }
        }

        .files-section {
          text-transform: uppercase;
          position: relative;

          .subtitle {
            align-items: center;
            border-bottom: 1px solid $sega-blue;
            display: flex;
            height: 50px;
            font-size: 20px;
            margin-top: 20px;
            position: relative;

            .icon {
              height: 35px;
              left: -50px;
              position: absolute;
              top: 5px;
              width: 35px;
            }
          }

          & > a {
            display: block;
            border-bottom: 1px solid $sega-blue;
            padding: 10px 0;
            transition: all 0.25s ease-in-out;

            &:hover {
              color: $sega-red;
            }
          }
        }

        .question {
          position: relative;
          padding: 10px 20px 10px 0;

          span {
            font-size: 36px;
            line-height: 0;
            position: absolute;
            top: 14px;
            right: 2px;
          }
        }

        .answer {
          border-bottom: 1px solid $sega-blue;
          display: block;
          color: $sega-black;
          text-transform: none;
          padding: 10px 0;

          &.is-hidden {
            display: none;
          }
        }
      }
    }

    &.marketing {
      .search-bar {
        .select-single {
          flex-shrink: 0;
        }

        .sega__enquiry_container_box_flex_check_list_item {
          flex-shrink: 0;
          margin: 0 30px;

          .container {
            margin-bottom: 0;
          }

          .checkMark {
            border: 2px solid $sega-blue;

            &:after {
              border: 2px solid $sega-blue;
              border-width: 0 3px 3px 0;
            }
          }

          .title {
            color: $sega-blue
          }
        }
      }

      .single-product-row .text-wrapper .go-to-game {
        border: 1px solid #7f99be;
        color: #7f99be;

        &:hover {
          background: #7f99be;
          color: #fff;
        }
      }

      .view-manuals-label {
        color: $sega-red;
      }
    }
  }

  &.order-history {
    color: $sega-black;

    form {
      color: $sega-blue;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;

      br {
        display: none;
      }

      input {
        display: inline-block;
        border: 1px solid $sega-dark-grey;
        border-radius: 5px;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
        height: 37px;
        margin: 0 10px;
        padding: 0 10px;
      }
    }

    .single-order {
      align-items: flex-start;
      background: $sega-white;
      box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: flex-start;
      line-height: 1.3;
      margin: 20px 0;
      padding: 15px;

      strong {
        color: $sega-blue;
        display: block;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .light-blue {
        color: $sega-light-blue;
        display: block;
      }
      .placed,
      .total {
        width: 30%;
      }

      .placed {
        border-right: 1px solid $sega-blue;
      }

      .dispatched {
        border-right: 1px solid $sega-blue;
        padding-left: 15px;
        width: 40%;

        span {
          display: inline-block;
        }
      }

      .total {
        padding-left: 15px;

        .button-blue {
          margin-top: 5px;
          padding: 3px 15px;
          text-align: center;
        }
      }
    }

    .order-details-header {
      align-items: flex-start;
      border-bottom: 1px solid $sega-blue;
      display: flex;
      width: 100%;

      h2 {
        border-bottom: 1px solid $sega-blue;
        color: $sega-blue;
        font-size: 18px;
        margin-top: 0;
        padding: 2px 15px 10px 15px;
        text-transform: uppercase;
      }

      span {
        display: block;
        padding: 0 15px;
      }

      .invoice-address,
      .delivery-address {
        padding-bottom: 15px;
        width: 50%;
      }

      .delivery-address {
        border-left: 1px solid $sega-blue;
      }
    }

    .details {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;

      .header {
        width: 100%;
      }

      h2 {
        border-bottom: 1px solid $sega-blue;
        color: $sega-blue;
        font-size: 18px;
        padding: 10px 15px;
        margin-top: 2px;
        text-transform: uppercase;
      }

      .order-number {
        color: $sega-blue;
        padding: 5px 15px;
        width: 200px;
      }

      .date {
        border-left: 1px solid $sega-blue;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 5px 15px;
        width: calc(100% - 200px);

        dt {
          color: $sega-blue;
          width: 40%;
        }

        dd {
          margin: 0;
          width: 60%;
        }
      }
    }

    .subtotal-table {
      background: $sega-white;
      border-radius: 5px;
      box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
      margin: 40px 0;
      padding: 10px;

      .items {
        border-collapse: collapse;
        width: 100%;

        thead tr th {
          border-bottom: 1px solid $sega-blue;
          color: $sega-blue;
          padding: 15px 10px;
          text-transform: uppercase;
        }

        tbody tr td {
          padding: 10px;
          text-align: center;

          &:nth-child(2) {
            text-align: left;
          }
        }
      }

      .subtotal {
        border-top: 1px solid $sega-blue;
        margin-top: 10px;
        margin-left: auto;
        max-width: 300px;
        text-transform: uppercase;
        width: 100%;
    
        span {
          display: flex;
          justify-content: flex-end;
          text-align: right;
          padding: 10px;

          .num {
            padding: 0;
            width: 50%;
          }

          &.total {
            border-top: 1px solid $sega-blue;
            color: $sega-blue;
            font-weight: 700;
          }
        }
      }
    }

    .btn-wrapper {
      text-align: right;
    }

    .buy-again {
      display: inline-block;
      margin-left: 10px;
      text-align: center;
      width: 240px;
    }

    .email-order,
    .buy-again {
      margin: 10px 0;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .sega-account__content-inner.manuals .single-product-row .text-wrapper .title {
    font-size: 32px;
  }

  .sega-account__content-inner.manuals.marketing .search-bar .sega__enquiry_container_box_flex_check_list_item {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 840px) {
  .sega-account__content-inner.manuals.marketing .search-bar {
    flex-wrap: wrap;

    .sega__enquiry_container_box_flex_check_list_item {
      margin: 0 20px 0 0;
    }

    #search-field {
      margin-bottom: 10px;
    }

    .search-bar-input {
      max-width: 100%;
      width: 100%;

      img {
        top: 40%
      }
    }
  }

  .sega-account__content-inner.order-history .single-order {
    flex-wrap: wrap;

    .placed,
    .total {
      order: 0;
      padding-bottom: 15px;
      width: 50%;
    }
    
    .dispatched {
      border-top: 1px solid $sega-blue;
      border-right: none;
      order: 1;
      padding: 15px 0 0 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 720px) {
  .sega-account__content-inner.manuals {
    .search-bar {
      &-input {
        margin-right: 10px;
        width: calc(100% - 130px);
      }

      .select-single {
        width: 140px;
      }
    }

    .single-product-row .text-wrapper .title {
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .sega-account__content-inner.order-history {
    .details {
      .order-number {
        width: 100%;
      }
  
      .date {
        border-left: none;
        border-top: 1px solid $sega-blue;
        width: 100%;
      }
    }

    .subtotal-table {
      .table-wrapper {
        overflow: auto;
      }
      
      .items {
        width: auto;
        min-width: 590px;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .sega-account__content-inner.account-settings .change-password-form .password-wrapper {
    .label {
      font-size: 16px;
      width: 120px;
    }

    input {
      width: calc(100% - 120px);
    }
  }

  .sega-account__content-inner.manuals {
    .search-bar {
      flex-wrap: wrap;
      margin-top: 0;

      &-input {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
      }

      .select-single {
        width: 100%;
      }
    }

    &.marketing .search-bar .select-single {
      width: 140px;
    }

    .alphabet {
      flex-wrap: wrap;
      justify-content: center;
  
      .alphabet-char {
        flex: inherit;
        margin-bottom: 10px;
        min-width: 30px;
      }
    }

    .single-product-row {
      flex-wrap: wrap;
      
      .text-wrapper {
        padding-left: 0;
        padding-top: 10px;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .sega-account__content-inner.order-history form br {
    display: block;
  }
}

@media only screen and (max-width: 400px) {
  .sega-account__content-inner.order-history .single-order {
    flex-wrap: wrap;

    .placed {
      border-right: 0;
      padding: 0 0 10px 0;
    }

    .dispatched {
      border-bottom: 1px solid $sega-blue;
      order: 0;
      padding: 10px 0;
    }

    .total {
      padding: 10px 0 0 0;
      width: 100%;
    }
  }
}