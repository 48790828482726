@import "../../variables/colors";
@import "../../variables/keyframes";

.sega__products {

  &_search {
    margin-top: 20px;

    form {
      justify-content: center;
      max-width: 720px;
      width: 100%;
      margin: auto;
      text-align: center;
      display: flex;
      position: relative;

      input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 42px;
        padding: 10px 55px 10px 20px;
        width: 100%;
        margin-top: 35px;
        margin-bottom: 15px;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
      }

      button {
        background: transparent;
        border: none;
        bottom: 15px;
        cursor: pointer;
        height: 42px;
        width: 35px;
        position: absolute;
        right: 20px;
        transition: 0.2s ease-in-out;

        img {
          left: 5px;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

    .header-text{
        padding-bottom: 0;
    }

  &_header {
    text-align: center;
    padding-bottom: 40px;

  .header-text {
      font-family: 'futura-pt-bold', sans-serif;
      font-weight: 700;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 20px;
        text-transform: uppercase;
    }

    h3 {
        text-transform: uppercase;
      padding: 0 15px;
    }
  }

    &_boxesMax {
        max-width: 1045px;
        padding-bottom: 20px;
    }

    &_boxes {
        padding-bottom: 40px;
        max-width: 1010px;
    }

  &_boxes, &_boxesMax {
    align-items: stretch;
    justify-content: center;
    margin: 15px auto 0 auto;
    display: flex;
    flex-wrap: wrap;

      h3 {
          margin-top: 15px;
          text-transform: uppercase;
      }

      &_productBlue {
          display: block;
          margin: 15px;
          width: 228px;
          filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.2));
          background-repeat: no-repeat !important;
          background-size: contain !important;

          img {
            height: 100%!important;
            object-fit: contain;
            width: 228px;
          }

          &_name {
              width: 100%;
              background-color: $sega-blue;
              opacity: .8;
              height: 50px;
              margin-top: -52px;
              display: block;
              text-align: center;

              span {
                  text-transform: uppercase;
                  color: $sega-white;
                  font-family: 'futura-pt-bold', sans-serif;
                  font-weight: 600;
                  width: 100%;
                  text-align: center;
                  padding-top: 14px;
                  font-size: 1rem;
                  display: block;
              }
          }
      }

      &_productWhite {
          display: block;
          margin: 15px;
          width: 228px;
          filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.2));
          background-repeat: no-repeat !important;
          background-size: contain !important;

          img {
            width: 100%;
          }

          &_name {
              width: 228px;
              background-color: $sega-white;
              min-height: 40px;
              margin-top: -4px;
              display: inline-flex;

              span {
                  text-transform: uppercase;
                  color: $sega-blue;
                  font-family: 'futura-pt-bold', sans-serif;
                  font-weight: 600;
                  width: 100%;
                  text-align: center;
                  font-size: 14px;
                  border-top: 1px solid;
                  max-width: 80%;
                  margin: 0 auto;
                  padding: 10px 5px;
              }
          }
      }

      &_productWhiteTextBlue {
          display: block;
          height: auto;
          margin: 15px;
          width: 228px;
          filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.2));
          background-repeat: no-repeat !important;
          background-size: contain !important;

          img {
            height: auto!important;
            object-fit: contain;
            width: 228px;
          }

          &_name {
              width: 228px;
              background-color: $sega-white;
              min-height: 40px;
              height: calc(100% - 228px);
              margin-top: -4px;
              display: inline-flex;

              span {
                  text-transform: uppercase;
                  color: $sega-blue;
                  font-family: 'futura-pt-bold', sans-serif;
                  font-weight: 600;
                  width: 100%;
                  text-align: center;
                  font-size: 14px;
                  max-width: 100%;
                  margin: 0 auto;
                  padding: 15px 5px;
              }
          }
      }
  }

    .border-line-parts-prizes {
        max-width: 1045px;
        margin: auto;
        border-top: 1px solid;
        border-color: $sega-blue;
    }

    .text-upper-parts-prizes {
        max-width: 1045px;
        margin: 20px auto;
        text-align: center;
        text-transform: uppercase;
    }

  @media only screen and (max-width: 820px) {

      &_header {
          padding: 0 15px;
      }

    &_search {
      form {
        input {
          margin: 35px 15px 15px 15px;
          width: calc(100% - 30px);
        }

        button {
          right: 30px;
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    &_search {
      form {
        button {
          margin-left: 260px;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    &_search {
      form {
        input {
          margin-top: 10px;
        }

        button {
          margin-top: 10px;
        }
      }
    }

    &_boxes, &_boxesMax {
      &_productBlue,
      &_productWhiteTextBlue {
        margin: 10px;
        width: calc(50% - 20px);

        img {
          width: 100%;
        }

        &_name {
          height: auto;
          width: 100%;

          span {
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    &__boxesMax {
      &_productWhite_name {
        span {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 450px) {
    &_search {
      form {
        button {
          margin-left: 160px;
        }
      }
    }
  }

  @media only screen and (max-width: 360px) {
    &_boxes, &_boxesMax {
      &_productBlue,
      &_productWhiteTextBlue {
        margin: 10px 20px;
        width: calc(100% - 40px);
      }
    }
  }
}

.display-prizes {
  display: flex;
  flex-wrap: wrap;
}

.display-pagination {
  margin-top: 50px;
  width: 100%;
}

.prizes_box_details {
  width: 221px;
  background-color: #fff;
  height: 50px;
  margin-top: -4px;
}

.prizes_box_details > span {
  display: inline-block;
  width: auto;
  text-transform: uppercase;
}

.prizes_box_details > a.prize_add_to_cart {
  display: inline-block;
  border: 1px solid #083a7e;
  padding: 2px 10px;
  text-transform: uppercase;
  border-radius: 16px;
}

#predictive-search {
  background: $sega-white;
  border: 2px solid $sega-blue;
  border-radius: 20px;
  display: none;
  margin-top: 50px;
  max-height: 400px;
  min-width: 300px;
  overflow: auto;
  padding: 10px 20px;
  position: absolute;
  white-space: nowrap;
  width: calc(100% - 40px);
  z-index: 10000;
}

.predictive-search-item {
  cursor: pointer;
  line-height: 1.4;
  margin: 12px 0;
  transition: all .2s ease;
  white-space: normal;

  &:hover {
    color: $sega-red;
  }
}


#home-predictive-search {
  background: $sega-white;
  border: 2px solid $sega-blue;
  border-radius: 20px;
  display: none;
  top: 100%;
  max-height: 400px;
  overflow: auto;
  padding: 10px 20px;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  z-index: 10000;

  .predictive-search-item {
    text-align: left;
  }
}

#banner-predictive-search {
  background: $sega-white;
  border-radius: 0;
  display: none;
  top: calc(100% + 10px);
  max-height: 400px;
  max-width: 1360px;
  overflow: auto;
  padding: 10px 20px;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  z-index: 10000;

  .predictive-search-item {
    text-align: center;
  }
}
