@import "../variables/colors";
@import "../variables/keyframes";

@mixin segaSelect {

    .icon_select_mate {
        position: absolute;
        top: 8px;
        right: 2%;
        transition: all .3s;

        .arrow {
            display: block;

            &:after {
                transform: rotate(90deg);
                display: inline-block;
                color: $sega-blue;
                content: '»';
                font-size: 26px;
                font-weight: 100;
            }
        }
    }

    .select_mate {
        position: relative;
        min-width: 210px;
        width: 210px;
        color: $sega-blue;
        font-weight: 900;
        font-family: 'futura-pt-bold', sans-serif;
        font-size: 20px;
        background-color: $sega-white;
        border: 2px solid $sega-dark-grey;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
        margin-top: 17px;

        select {
            position: absolute;
            overflow: hidden;
            height: 0;
            opacity: 0;
            z-index: -1;
        }
    }

    .cont_select_int {
        position: absolute;
        left: 0;
        top: 45px;
        z-index: 999;
        overflow: hidden;
        height: 0;
        width: 100%;
        background-color: $sega-white;
        padding: 0;
        margin-bottom: 0;
        margin-top: 0;
        border-radius: 5px;
        box-shadow: 1px 4px 10px -2px rgba($sega-black, 0.2);
        transition: all 0.4s ease-in-out;

        li {
            position: relative;
            width: 96%;
            border-bottom:1px solid $sega-grey;
            background-color: $sega-white;
            color: $sega-blue;
            list-style-type: none;
            padding: 10px 2%;
            margin: 0;
            font-size: 14px;
            font-weight: 300;
            transition: all 0.3s ease-in-out;
            display: block;
            cursor:pointer;

            &:last-child {
                border-radius: 3px;
                border-bottom: 0;

            }
            &:hover {
                background-color: $sega-grey;
            }
        }

        .active {
            background-color: $sega-grey;
        }

    }

    .selected_option {
        white-space: nowrap;
        padding: 10px 2%;
        width: 96%;
        display: block;
        margin: 0;
        cursor: pointer;
    }
}

@mixin buttonHeart {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
    text-align: center;
    font-family: 'futura-pt-bold', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 20px;
    color: $sega-blue;
    transition: 0.2s ease-in-out;

    svg {
        width: 18px;
        vertical-align: bottom;
        stroke: $sega-blue;
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 3px;
        transition: 0.2s ease-in-out;
    }

    &:hover {
        transition: 0.2s ease-in-out;
        color: rgba($sega-red, 0.8);

        svg {
            transition: 0.2s ease-in-out;
            stroke: $sega-red;
        }
    }

    &:active {
        transition: 0.2s ease-in-out;
        transform: scale(0.8);

    }
}
@mixin buttonBlue {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
    text-align: center;
    font-family: 'futura-pt-bold', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 20px;
    color: $sega-blue;
    transition: 0.2s ease-in-out;

    svg {
        width: 18px;
        vertical-align: bottom;
        stroke: $sega-blue;
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 3px;
        transition: 0.2s ease-in-out;
    }

    &:hover {
        transition: 0.2s ease-in-out;
        color: rgba($sega-red, 0.8);

        svg {
            transition: 0.2s ease-in-out;
            stroke: $sega-red;
        }
    }

    &:active {
        transition: 0.2s ease-in-out;
        transform: scale(0.8);

    }
}

@mixin productText {
    max-width: 980px;
    display: block;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    .blue-text {
        color: $sega-light-blue;

        span {
            color: $sega-blue;
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: 800;
        }
    }

    .font-large {
        font-size: 40px;
    }

    h3 {
        white-space: nowrap;
        margin-left: 10px;
        font-size: 34px;
        text-transform: uppercase;
        font-weight: 900;
        font-family: 'futura-pt-bold', sans-serif;
        margin-bottom: 10px;
    }

    h4 {
        text-transform: uppercase;
        font-family: 'futura-pt-bold', sans-serif;
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 20px;
    }

    p {
        color: $sega-black;
        margin-bottom: 20px;
        margin-top: 10px;

        img {
            max-width: 100%;
        }
    }
}

@mixin niceSelect {

    $font_size: 14px !default;
    $font_size_small: 12px !default;

    $input_border_radius: 5px !default;
    $input_height: 42px !default;
    $input_height_small: 36px !default;
    $dropdown_padding: 18px !default;

    $gray_dark: #444 !default;
    $gray: #999 !default;
    $gray_light: #e8e8e8 !default;
    $gray_lighter: #f6f6f6 !default;
    $primary_light: $gray !default;
    $arrow_color: $gray !default;

    .nice-select {
        margin-top: 20px;
        min-width: 10rem;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        background-color: #fff;
        border-radius: $input_border_radius;
        border: solid 2px $sega-dark-grey;
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        color: $sega-blue;
        font-weight: 900;
        font-family: 'futura-pt-bold', sans-serif;
        font-size: 20px;
        height: $input_height;
        line-height: $input_height - 2;
        outline: none;
        padding-left: $dropdown_padding;
        padding-right: $dropdown_padding + 12;
        position: relative;
        text-align: left !important;
        transition: all 0.2s ease-in-out;
        user-select: none;
        white-space: nowrap;
        width: auto;

        @media only screen and (max-width: 850px) {
            margin-top: 0;
        }

        &:hover {
            border-color: darken($gray_light, 5%);
        }

        &:active, &:focus {
            border-color: $primary_light;
        }

        &:after {
            content: '»';
            color: $sega-blue;
            display: block;
            font-size: 26px;
            font-weight: 100;
            pointer-events: none;
            position: absolute;
            right: 12px;
            top: 0%;
            transform: rotate(90deg);
            transition: all 0.15s ease-in-out;
        }

        &.open {
            @extend :active;

            &:after {
                transform: rotate(270deg);
            }

            .list {
                opacity: 1;
                pointer-events: auto;
                transform: scale(1) translateY(0);
            }
        }

        &.disabled {
            border-color: lighten($gray_light, 2%);
            color: $gray;
            pointer-events: none;
            &:after {
                border-color: lighten($arrow_color, 20%);
            }
        }

        &.wide {
            width: 100%;

            .list {
                left: 0 !important;
                right: 0 !important;
            }
        }

        &.right {
            float: right;

            .list {
                left: auto;
                right: 0;
            }
        }

        &.small {
            font-size: $font_size_small;
            height: $input_height_small;
            line-height: $input_height_small - 2;

            &:after {
                height: 4px;
                width: 4px;
            }

            .option {
                line-height: $input_height_small - 2;
                min-height: $input_height_small - 2;
            }
        }

        .list {
            width: 100%;
            background-color: #fff;
            border-radius: $input_border_radius;
            box-shadow: 0 0 0 1px rgba($gray_dark, .11);
            box-sizing: border-box;
            max-height: 300px;
            margin-top: 4px;
            opacity: 0;
            overflow: auto;
            padding: 0;
            pointer-events: none;
            position: absolute;
            top: 100%; left: 0;
            transform-origin: 50% 0;
            transform: scale(.75) translateY(- $input_height / 2);
            transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
            z-index: 99;

            &:hover .option:not(:hover) {
                background-color: transparent !important;
            }
        }

        .option {
            color: $sega-blue;
            font-weight: 100;
            font-size: 14px;
            cursor: pointer;
            width: 100%;
            line-height: $input_height - 2;
            list-style: none;
            min-height: $input_height - 2;
            outline: none;
            padding-left: $dropdown_padding;
            padding-right: $dropdown_padding + 11;
            text-align: left;
            transition: all 0.2s;

            &:hover, &.focus {
                background-color: $sega-red;
                color: $sega-white;
            }

            &.selected {
                background-color: $sega-grey;
                color: $sega-blue;
                font-weight: bold;
            }

            &.disabled {
                background-color: transparent;
                color: $gray;
                cursor: default;
            }
        }

        .current {
            margin-right: 5px;
            text-transform: uppercase;
        }
    }

    .no-csspointerevents .nice-select {

        .list {
            display: none;
        }

        &.open {

            .list {
                display: block;
            }
        }
    }

}

@mixin segaCheckbox {

    .container {
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 5px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkMark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        border-radius: 5px;
        border: 2px solid $sega-blue;
    }

    .container input:checked ~ .checkMark {
        border: 2px solid $sega-red;
    }

    .container input:checked ~ .title {
        color: $sega-red;
    }

    .container input:checked:disabled {
        cursor: none!important;

        & ~ .checkMark {
            border: 2px solid #979797;

            &:after {
                border: 2px solid #979797;
                border-width: 0 3px 3px 0;
            }
        }
    }

    .container input:checked:disabled ~ .title {
        color: #979797;
    }

    .checkMark:after {
        content: '';
        position: absolute;
        display: none;
    }
    .checkMark:before {
        content: '';
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkMark:after,
    .container input:checked ~ .checkMark:before {
        display: block;
    }

    .container .checkMark:after {
        left: 3px;
        position: absolute;
        top: -3px;
        width: 8px;
        height: 12px;
        border: 2px solid $sega-red;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
    .container .checkMark:before {
        left: 3px;
        position: absolute;
        top: -3px;
        width: 8px;
        height: 12px;
        border: 2px solid $sega-white;
        border-width: 0 7px 3px 0;
        transform: rotate(71deg);
    }
}

@mixin segaSlider {
    margin: auto;
    text-align: center;

    h1 {
        display: none;
        font-size: 20px;
        text-transform: uppercase;
        &:first-child {
            margin-top: 20px;
        }
        &:last-child {
            margin-top: 0;
        }
    }

    .button_arrow {
        margin-top: 20px;
        cursor: pointer;
        margin-bottom: 36px;
        font-weight: 900;
        font-family: 'futura-pt-bold', sans-serif;
        font-size: 35px;
        outline: none;
        border: none;
        background-color: transparent;

        &:after {
            transform: rotate(90deg) scale(1);
            display: block;
            color: $sega-blue;
            content: '»';
            font-size: 37px;
            font-weight: 100;
        }

        &:hover {

            &:after {
                color: $sega-red;
                transition: 0.2s ease-in-out;
                opacity: 1;
            }
        }
    }

    @media only screen and (max-width: 600px) {

        .button_arrow {
            margin-bottom: 20px;
        }
    }
}

@mixin inputText {
    display: block;
    border: 1px solid $sega-dark-grey;
    box-shadow: 0 0 15px 2px rgba(0,0,0,0.10);
    width: 100%;
    height: 37px;
    border-radius: 5px;
    padding: 0 10px;

    &:disabled {
        background: #eaeaea;
        border: 1px solid $sega-grey;
    }
}

@mixin asideGameMenu {
    display: block;
    margin: 0;
    padding: 15px 0;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:after {
        transform: rotate(-90deg) scale(1);
        color: $sega-blue;
        display: inline-block;
        content: '»';
        font-size: 23px;
        font-weight: 100;
        margin-left: 10px;
        transition: all 0.2s ease-in-out;
    }
}

@mixin menu {
    text-transform: uppercase;
    display: flex;
    text-align: center;
    font-family: 'futura-pt-bold', sans-serif;
    font-weight: 600;
}

@mixin asideGameList {
    display: none;
    width: fit-content;
    text-align: left;
    margin: 0 auto 20px auto;
}

@mixin searchBar {
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
    height: 95px;
    background: $sega-white;
    display: flex;
    justify-content: center;

    input {
        margin-top: 11px;
        width: 1015px;

        &::placeholder {
            padding: 15px;
            font-size: 35px;
            font-weight: 900;
            font-family: 'futura-pt-bold', sans-serif;
            text-transform: uppercase;
            color: $sega-light-blue;
            text-align: center;
            opacity: 1;
        }
    }

    input:focus::placeholder {
        padding: 15px;
        font-size: 35px;
        font-weight: 900;
        font-family: 'futura-pt-bold', sans-serif;
        text-transform: uppercase;
        color: $sega-light-blue;
        text-align: center;
        opacity: 0;
    }

    input:valid {
        padding-left: 30px;
        font-size: 20px;
        text-align: center;
    }

    button,
    .close-search {
        cursor: pointer;
        outline: none;
        border: none;
        background-color: transparent;
        transition: 0.2s ease-in-out;

        &:active {
            transform: scale(0.9);
        }
    }

    .close-search {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 5px;

        img {
            display: block;
            height: auto;
            position: relative;
            top: -2px;
            width: 34px;
        }
    }
}

@mixin repetitionCommands {
    tr {
        margin-bottom: 0;
        padding-bottom: 10px;
        margin-top: 10px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }

        th {
            margin-block: auto;

            a {
                margin-block: auto;
            }
        }
    }

    .marginTop {
        display: none;
    }

    .sega__articles_container_video {
        margin-top: 0;
        text-align: center;

        h3 {
            font-weight: 900;
            font-family: 'futura-pt-bold', sans-serif;
            font-size: 34px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 10px;
        }

        h4 {
            text-transform: uppercase;
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: 800;
            font-size: 20px;
            margin-bottom: 20px;
        }
    }
}

@mixin containerPanelTab {
    margin-top: 20px;

    &_box {
        display: flex;
        flex-wrap: wrap
    }

    .display-games {
        justify-content: center;
    }

    h3 {
        text-transform: uppercase;
        display: block;
        font-size: 14px;
        margin-bottom: 20px;
        font-family: 'futura-pt-bold', sans-serif;
        font-weight: 600;
        max-height: 100px;
    }

    h2 {
        text-transform: uppercase;
        display: block;
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: 800;
        font-family: 'futura-pt-bold', sans-serif;
        width: 100%;
        margin-top: 10px;
    }

    .border-line {
        margin: 25px 0 20px 0;
    }

    &_product {
        filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.2));

        &:hover {
            transition: all 0.2s ease-in-out;
            filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.4));

            &_name {
                span {
                    color: $sega-red;
                }
            }
        }
    }

    &_product {
        background: #fff;
    }

    &_product, &_person, &_lastBox {
        display: block;
        margin: 10px;
        width: 222px;
        background-repeat: no-repeat;
        transition: all 0.2s ease-in-out;

        img {
            height: 222px!important;
            object-fit: contain;
            width: 222px;
        }

        &_name {
            width: 222px;
            background-color: $sega-white;
            min-height: 50px;
            display: inline-flex;
            justify-content: center;

            span {
                text-transform: uppercase;
                border-top: 1px solid rgba($sega-blue, 0.5);
                padding: 10px 0;
                color: $sega-blue;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 900;
                text-align: center;
                margin-top: 17px;
                font-size: 12px;
                width: 80%;
            }
        }

        &_info {

            h3 {
                font-size: 14px;
                text-align: center;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
                &:first-child {
                    margin-top: 10px;
                }

                & + h3 {
                    color: $sega-blue-light
                }
            }

            &_list, ul {

                &_item, li {
                    display: flex;
                    margin-top: 0;
                    justify-content: center;
                    text-align: center;

                    img {
                        height: 100%!important;
                        width: 15px;
                        object-fit: fill;
                        padding-top: 9px;
                        margin-right: 7px;
                        background-repeat: no-repeat;
                    }

                    a, span {
                        // line-break: anywhere;
                        margin-top: 9px;
                        font-size: 13px;
                        color: $sega-black;
                    }
                }
            }
        }
    }

    &_person {

        img {
            filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.2));
        }
    }
}
