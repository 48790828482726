@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

@mixin inputLabelText {
    text-transform: capitalize;
    color: $sega-blue;
    font-size: 20px;
    display: block;
    margin-bottom: 5px;

    .info {
        font-size: 16px;
        display: inline-block;
    }
}

.sega__enquiry {
    margin: 30px auto 0 auto;

    &.sing-up {
        margin-top: 60px;

        .sega__enquiry_container_box_email,
        .sega__enquiry_container_box_more,
        .sega__enquiry_container_box_company {
            margin-top: 20px;
        }

        .select-single .select2.select2-container .select2-selection--single .select2-selection__rendered {
            font-weight: 400;
        }

        .thanks-title {
            margin: 100px 0;
            text-align: center;
            text-transform: uppercase;
        }
    }

    h3 {
        text-align: center;
        font-size: 22.5px;
        text-transform: uppercase;
        max-width: 900px;
        margin: auto;

        &.sega__enquiry_container_subtitle {
            font-size: 20px;
            font-family: 'futura-pt';
            font-weight: 400;
            margin: 50px 0 0 0;
            text-align: left;
            text-transform: none;

            &+.sega__enquiry_container_box_email {
                margin-top: 10px;
            }

            &.mt-10 {
                margin-top: 10px;
            }
        }
    }

    &_container {
        max-width: 900px;
        margin: auto;

        &_white {
            background: $sega-white;
            border: solid $sega-grey;
            border-width: 5px 0;
            box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
            margin: 20px 0 40px 0;
            padding: 40px 20px;

            h3.sega__enquiry_container_subtitle {
                margin-top: 0;
            }
        }

        &_box {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 30px;
            }

            .single-file {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 15px;
            }

            .remove-file {
                font-weight: 700;
                margin-left: 3px;
                flex-shrink: 0;
                width: 10px;

                &:hover {
                    color: $sega-red
                }
            }

            .title-box {
                @include inputLabelText;
                margin-top: 40px;
                display: block;

                &.mt-10 {
                    margin-top: 10px;
                }

                &.color-red {
                    color: $sega-red
                }
            }

            textarea {
                @include inputText;
                height: 80px;
                max-width: 900px;
                padding: 10px;
                max-height: 500px;
                min-height: 80px;

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    opacity: 0.5;
                }
            }

            &_email {
                margin-top: 30px;

                .input-box {
                    display: block;

                    label {
                        @include inputLabelText;

                        &.only-mobile {
                            display: none
                        }

                        &.no-capital {
                            text-transform: none;
                        }
                    }

                    input {
                        @include inputText;
                    }

                    .info {
                        color: $sega-blue-light;
                        display: inline-block;
                        font-size: 15px;
                        margin-bottom: 5px;
                    }
                }

                .w30 {
                    width: 30%;
                }

                .w50 {
                    width: 50%;
                }

                .w50-10p {
                    width: calc(50% - 10px);
                }
            }

            &_more {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;

                &.flex-wrap {
                    flex-wrap: wrap;

                    .error-validation {
                        margin-top: -25px;
                        margin-bottom: 25px;
                        width: 100%;
                    }
                }

                .input-box {
                    width: 30%;
                    display: block;

                    &.w70p {
                        width: 70px
                    }

                    &.w50-50p {
                        width: calc(50% - 50px);
                    }

                    &.w50-10p {
                        width: calc(50% - 10px);
                    }

                    label {
                        @include inputLabelText;
                    }

                    input {
                        @include inputText;
                    }

                    .input-box-select {
                        margin-left: 0;
                        @include niceSelect;

                        .select_mate {

                            select {
                                position: absolute;
                                overflow: hidden;
                                height: 0;
                                opacity: 0;
                                z-index: -1;
                            }

                            .nice-select {
                                box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.10);
                                margin-top: 0;
                                max-width: none;
                                width: 100%;
                                border: none;
                                height: 37px;

                                .current {
                                    font-weight: 200;
                                    font-size: 18px;
                                    display: block;
                                    margin-top: -1px;
                                    color: rgba(0, 0, 0, 0.8);
                                }
                            }
                        }
                    }

                    &.color-block {
                        margin-bottom: 30px;

                        .warranty-radio-input {
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            height: 100%;
                            padding: 20px;
                            position: relative;


                            &::after {
                                bottom: 25px;
                                content: '»';
                                color: $sega-blue;
                                display: block;
                                font-size: 42px;
                                font-weight: 100;
                                line-height: 0;
                                pointer-events: none;
                                position: absolute;
                                right: 12px;
                                transition: all .25s ease-in-out;
                            }

                            &:hover {
                                &::after {
                                    color: $sega-red;
                                }
                            }
                        }

                        input[type="radio"] {
                            appearance: none;
                            background: #fff;
                            cursor: pointer;
                            display: block !important;
                            height: 100% !important;
                            left: 0;
                            position: absolute;
                            top: 0;
                            width: 100% !important;
                            z-index: 0;
                        }

                        input[type="radio"]:checked {
                            background: $sega-blue;

                            &+.label-text {
                                color: $sega-white
                            }
                        }

                        .label-text {
                            display: block;
                            pointer-events: none;
                            position: relative;
                            z-index: 1;
                        }
                    }
                }

                .sega__productsTab_panel_form_box_select {
                    margin-left: 10px;
                    margin-right: 10px;

                    label {
                        text-transform: capitalize;
                        color: $sega-blue;
                        font-size: 20px;
                        display: block;
                        margin-bottom: 5px;
                    }

                    .select2.select2-container {
                        margin-top: 0;
                    }
                }
            }

            &_company {
                margin-top: 15px;

                .input-box {
                    display: block;

                    label {
                        @include inputLabelText;
                    }

                    input {
                        @include inputText;
                    }
                }
            }

            &_flex {
                display: flex;
                flex-wrap: wrap;
                width: 860px;
                margin: 15px auto;

                &_check {
                    width: 50%;

                    h4 {
                        font-weight: 200;
                        display: block;
                        margin-bottom: 10px;
                        text-transform: capitalize;
                        color: $sega-blue;
                        font-size: 20px;
                    }

                    &_list {

                        &_item {
                            margin: 15px 0;
                            @include segaCheckbox;
                            position: relative;

                            .title {
                                font-size: 19px;
                                text-transform: capitalize;
                                color: $sega-black;

                                a {
                                    color: $sega-red;

                                    &:hover {
                                        color: $sega-blue
                                    }
                                }
                            }

                            .checkMark {
                                margin-top: 2px;
                                border: 2px solid $sega-black;
                            }

                            .other-input,
                            .other-input-rwd {
                                @include inputText;
                                height: 37px !important;
                                display: block;
                                opacity: 1 !important;
                                position: absolute;
                                right: 0;
                                top: -8px;
                                width: calc(100% - 100px) !important;

                                &::placeholder {
                                    opacity: 0.5;
                                }

                                &--active {
                                    display: block;
                                }
                            }

                            .container {
                                display: flex;
                                flex-wrap: wrap;
                            }

                            .container-other-rwd {
                                display: none;
                            }

                            &.extra-input {
                                align-items: center;
                                display: flex;
                                justify-content: space-between;
                                margin: 10px 0;

                                .container {
                                    margin-bottom: 0;
                                    width: calc(50% - 10px);
                                }

                                .input-box,
                                .sega__productsTab_panel_form_box_select {
                                    display: block;
                                    width: calc(50% - 10px);
                                }

                                .input-box input {
                                    @include inputText;
                                }

                                .sega__productsTab_panel_form_box_select .select2.select2-container {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    .marginTop {
                        margin-top: 35px;
                    }

                    &:last-of-type {
                        margin-left: 0;
                        width: 100%;

                        .sega__enquiry_container_box_flex_check_list_item {
                            margin-top: 0;
                        }
                    }
                }
            }

            &_check {
                width: 860px;
                margin: 15px auto;

                h4 {
                    font-weight: 200;
                    @include inputLabelText;
                }

                &_list {

                    &_point {
                        @include segaCheckbox;
                        margin: 15px 0;

                        .container {

                            .title {
                                font-size: 19px;

                                span {
                                    display: block;
                                    font-size: 15px;
                                    color: $sega-light-blue;
                                }
                            }
                        }

                        .checkMark {
                            margin-top: 2px;
                            border: 1px solid rgba(0, 0, 0, 0.3);
                            border-radius: 100% !important;
                        }

                        .container input:checked~.title {
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 900;
                            color: $sega-blue;

                            span {
                                font-family: 'futura-pt-bold', sans-serif;
                                font-weight: 900;
                                color: $sega-sky-blue;
                            }
                        }

                        .container input:checked~.checkMark {
                            border: 5.5px solid $sega-sky-blue;
                            background-color: $sega-white !important;
                        }

                        .container .checkMark:after {
                            display: none !important;
                        }

                        .container .checkMark:before {
                            display: none !important;
                        }
                    }

                    &_item {
                        margin: 15px 0;
                        @include segaCheckbox;

                        .title {
                            font-size: 19px;
                            text-transform: capitalize;
                            color: $sega-black;

                            a {
                                color: $sega-red;

                                &:hover {
                                    color: $sega-blue
                                }
                            }
                        }

                        .checkMark {
                            margin-top: 2px;
                            border: 2px solid $sega-black;
                        }

                        .other-input {
                            @include inputText;
                            width: 800px !important;
                            height: 37px !important;
                            opacity: 1 !important;
                            left: 25%;
                            top: -8px;

                            &::placeholder {
                                opacity: 0.5;
                            }
                        }

                        .container {
                            display: flex;
                        }
                    }
                }
            }

            .marginLeft {
                margin: 30px 0 15px 0;
            }
        }

        &.return-form {
            margin-top: 65px;

            h3 {
                margin-top: 45px;

                &+p {
                    margin: 15px 0;
                }
            }

            p {
                font-size: 20px;

                &.highlighted {
                    color: $sega-red;
                    margin-bottom: 50px;
                }
            }

            .sega__enquiry_container_box_email {
                margin-top: 20px;
            }

            .sega__enquiry_container_box_more {
                margin-top: 10px;
            }

            .items-header {
                align-items: center;
                font-size: 18px;
                margin-top: 10px;

                .items-row {
                    margin: 0 -5px;

                    &>.h-col {
                        border-right: none;
                        position: relative;

                        &:after {
                            background: $sega-blue;
                            content: '';
                            display: block;
                            height: 20px;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1px;
                        }
                    }
                }
            }

            #items {
                .items-row {
                    margin-top: 10px;
                    position: relative;

                    .remove-item-row {
                        position: absolute;
                        right: -10px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                .sega__productsTab_panel_form_box_select .select2.select2-container {
                    margin-top: 0;
                }

                .sega__enquiry_container_box_email .input-box input {
                    height: 100%;
                    min-height: 37px;
                }

                .select-single .select2.select2-container {
                    height: auto;

                    .select2-selection--single {
                        height: auto;

                        .select2-selection__rendered {
                            align-items: center;
                            color: #444;
                            font-weight: 400;
                            font-size: 12px;
                            display: flex;
                            height: auto;
                            min-height: 44px;
                            line-height: 1.4;
                            padding-right: 30px;
                            word-break: break-all;
                            white-space: break-spaces;
                        }
                    }
                }
            }

            #add-item-button {
                background: $sega-white;
                border-radius: 5px;
                border: 1px solid #e9e9e9;
                box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
                display: inline-block;
                font-weight: bold;
                font-size: 18px;
                height: 52px;
                line-height: 52px;
                margin: 10px 5px;
                padding: 0 15px;
                text-transform: uppercase;
            }

            .additional-comments {
                margin: 20px 5px;

                label {
                    display: inline-block;
                    font-size: 18px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .input__button_red {
        padding: 30px 0 50px 0;
        text-align: center;
        display: none;

        button {
            text-transform: uppercase;
            font-size: 20px;
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: 900;
            max-width: 300px;
        }
    }

    .active {
        display: block !important;
    }

    @media only screen and (max-width: 1200px) {
        &_container {
            &_box {
                &_email {
                    .input-box {
                        label {
                            font-size: 18px;
                        }
                    }
                }

                &_more {
                    .input-box {
                        label {
                            font-size: 18px;
                        }
                    }

                    .sega__productsTab_panel_form_box_select label {
                        font-size: 18px;
                    }
                }

                &_company {
                    .input-box {
                        label {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        &_container_box_more .sega__productsTab_panel_form_box_select {
            text-align: left;
            width: 48%;
        }
    }

    @media only screen and (max-width: 970px) {
        padding: 0 20px;

        &.new-ca {
            padding: 0;
        }

        &_container {
            &.return-form .items-header {
                font-size: 16px;
            }

            &_box {
                &_email {
                    .input-box {
                        label {
                            font-size: 16px;
                            white-space: nowrap;
                        }
                    }
                }

                &_more {
                    .input-box {
                        label {
                            font-size: 16px;
                            white-space: nowrap;
                        }
                    }

                    .sega__productsTab_panel_form_box_select label {
                        font-size: 16px;
                    }
                }

                &_company {
                    .input-box {
                        label {
                            font-size: 16px;
                            white-space: nowrap;
                        }
                    }
                }

                &_flex {
                    width: auto;
                    margin: 15px;

                    &_check {
                        &_list {
                            &_item {
                                .title {
                                    font-size: 17px;
                                }
                            }
                        }
                    }
                }

                &_check {
                    width: auto;

                    &_list {
                        &_point {
                            .container {
                                .title {
                                    font-size: 17px;

                                    span {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    @media only screen and (max-width: 720px) {
        padding: 0 20px;

        &_container {
            &.return-form .items-header {
                font-size: 14px;
            }

            &_box {
                .title-box {
                    text-align: center;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 800;
                }

                &_email {
                    .input-box {
                        label {
                            text-align: center;
                        }
                    }

                    .w30,
                    .w50,
                    .w50-10px {
                        width: 100%;
                    }
                }

                &_more {
                    width: 100%;
                    display: block;

                    .input-box {
                        width: 100%;
                        display: block;

                        label {
                            width: 100%;
                            justify-content: center;
                            display: inline-flex;

                            br {
                                display: none;
                            }
                        }

                        &.w70p,
                        &.w50-10p {
                            width: 100%;
                        }

                        &.w50-50p {
                            margin-top: 20px;
                            width: calc(50% - 10px);
                        }
                    }

                    .sega__productsTab_panel_form_box_select {
                        margin: 20px 0 0 0;
                        text-align: center;
                        width: 100%;

                        .nice-select {
                            margin-bottom: 20px;
                        }
                    }
                }

                &_company {
                    .input-box {
                        label {
                            text-align: center;
                        }
                    }
                }

                &_flex {
                    display: block;

                    &_check {
                        width: 100%;

                        &_list {
                            &_item {
                                .other-input-rwd {
                                    margin-top: 10px;
                                    position: initial !important;
                                }

                                .container-other {
                                    display: none;
                                }

                                .container-other-rwd {
                                    display: block;
                                }
                            }
                        }

                        .marginTop {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-left: 0;
                        }
                    }
                }

                &_check {
                    width: auto;
                }
            }
        }
    }

    @media only screen and (max-width: 400px) {
        &_container {
            &_box {

                &_flex_check_list_item .other-input,
                &_flex_check_list_item .other-input-rwd {
                    margin-left: auto;
                    position: relative;
                    top: 5px;
                    width: calc(100% - 22px) !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1020px) {
    #sign-up-form {
        .sega__productsTab_panel_form_box_select {
            text-align: left;
        }
    }
}

@media only screen and (max-width: 720px) {
    #sign-up-form {
        .sega__productsTab_panel_form_box_select {
            text-align: center;
        }

        .sega__enquiry_container_box_more {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

@media only screen and (max-width: 560px) {
    .sega__enquiry_container_box_more .input-box.w50-50p {
        width: 100%
    }

    .sega__enquiry_container_box_flex_check_list_item.extra-input {
        flex-wrap: wrap;

        .container {
            width: 100%
        }

        .input-box,
        .sega__productsTab_panel_form_box_select {
            margin-top: 10px;
            padding-left: 25px;
            width: 100%;
        }
    }

    .sega__enquiry_container_box .warranty-upload-file {
        width: calc(100% - 15px) !important;
    }

    .sega__enquiry_container.return-form {
        .items-header {
            display: none;
        }

        .items-row {
            flex-wrap: wrap;
        }

        #items .sega__enquiry_container_box_email .input-box input {
            height: auto;
            min-height: 44px;
        }

        .sega__enquiry_container_box_email .input-box label.only-mobile {
            display: block;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .item-col-20,
        .item-col-25,
        .item-col-30 {
            padding: 8px 4px;
            width: 50%;

            br {
                display: none;
            }
        }

        .h-col {
            &:nth-of-type(1) {
                border-bottom: 1px solid $sega-blue;
            }

            &:nth-of-type(2) {
                border-bottom: 1px solid $sega-blue;
                border-right: 0;
            }
        }
    }
}


@media only screen and (max-width: 450px) {
    .sega__enquiry_container.return-form {

        .item-col-20,
        .item-col-25,
        .item-col-30 {
            padding: 8px 0;
            width: 100%;
        }
    }

    .sega__enquiry_container_box_more .input-box.color-block .label-text {
        white-space: wrap;
    }
}
