@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__windowPopup {
    display: none;
    top: 0;
    position: fixed;
    width: 100vw;
    margin-top: -20px;
    height: 105vh;
    z-index: 99999;
    animation: opacity 0.7s;
    background-color: rgba(3, 27, 34, 0.16);

    &_content {
        max-width: 610px;
        height: 350px;
        border-radius: 10px;
        background-color: #f5f5f5;
        margin: 33vh auto;
        padding: 40px;
        text-align: center;
        width: 90%;

        &_close {
            cursor: pointer;
            border: none;
            margin-left: 95%;
            font-size: 28px;
            padding: 0;
            margin-top: -20px;
            margin-bottom: -25px;
            color: #FF0000;
            outline: none;
            background: transparent;

        }
        &_header {
            padding-top: 75px;
            position: relative;

            &:before {
                background-image: url('/assets/img/sega_icon_popup_check.png');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                content: '';
                font-size: 75px;
                color: $sega-red;
                height: 53px;
                left: calc(50% - 24px);
                position: absolute;
                top: 0px;
                margin-bottom: 20px;
                width: 48px
            }
            
            h3 {
                font-size: 40px;
                color: $sega-blue;
                text-transform: uppercase;
            }

            &.is-invalid {
                &:before {
                    background-image: url('/assets/img/sega_icon_popup_exclamation-mark.svg');
                }
            }
        }
        &_body {
            font-size: 18px;
            margin-top: 15px;
            color: $sega-blue;

            .buttons {
                margin-top: 25px;
            }

            a {
                display: inline-block;
                margin: 0 10px;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }

            .button {
                background: $sega-blue;
                border: 1px solid $sega-blue;
                border-radius: 20px;
                color: $sega-white;
                height: 40px;
                line-height: 37px;
                padding: 0 15px;
                text-decoration: none;
                transition: all 0.2s ease-in-out;

                &:active,
                &:focus,
                &:hover {
                    background: $sega-white;
                    color: $sega-blue;
                }
            }

            .error-text {
                color: $sega-red
            }
        }
    }
}

@media only screen and (max-width: 560px) {
    .sega__windowPopup_content {
        padding: 30px;

        &_body .button {
            margin: 10px
        }

        &_header h3 {
            font-size: 32px;
        }
    }
}