@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__enquiryProduct {
    padding-bottom: 25px;

    &_box {
        display: flex;
        height: 200px;
        max-width: 900px;
        width: 100%;
        margin: 0 auto 25px auto;
        box-shadow: 0 0 15px 2px rgba(0,0,0,0.10);
        justify-content: space-between;
        background-color: $sega-white;

        img {
            width: 155px;
            height: 155px!important;
            object-fit: cover;
            box-shadow: 0 0 15px 2px rgba(0,0,0,0.10);
            margin-block: auto;
            margin-left: 20px;
        }

        h3 {
            display: block;
            margin-block: auto;
            font-size: 25px;
            text-transform: uppercase;
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: 900;
        }

        &_button {
            margin-right: 60px;
            border: none;
            background: transparent;
            width: 100px;
            height: 90px;
            margin-block: auto;
            text-transform: uppercase;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:active {
                transform: scale(0.8);
                transition: all 0.2s ease-in-out;
            }

            .line {
                width: 100%;
                height: 13px;;
                background-color: $sega-blue;
                display: block;
                border-radius: 2px;
                transition: all 0.5s ease;

                &:nth-child(1) {
                    top: 20px;
                    left: 0;
                    margin-top: 34px;
                    border-radius: 40px;
                    border: 3px solid $sega-blue;
                    transform: rotate(45deg) translate(-5.5px,-0.5px);
                }

                &:nth-child(2) {
                    top: 10px;
                    right: 0;
                    margin-top: -10px;
                    border-radius: 40px;
                    border: 3px solid $sega-blue;
                    transform: rotate(129deg) translate(-3.5px,4.5px);
                }
            }

            .text {
                margin-top: 35px;
                display: block;
                text-transform: uppercase !important;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 900;
            }
        }

        &--remove {
            display: none !important;
        }
    }
    @media only screen and (max-width: 970px) {
        padding: 0 20px;

        &_box {
            h3 {
                max-width: 285px;
            }
        }
    }
    @media only screen and (max-width: 720px) {

        &_box {
            display: block;
            height: auto;
            width: 100%;
            text-align: center;
            padding: 30px 0;

            img {
                margin: auto;
            }

            h3 {
                margin: 20px auto;
            }

            button {
                margin: auto;
                width: 80px;

                .line {

                    &:nth-child(1) {
                        transform: rotate(45deg) translate(-3.5px,-.5px);
                    }
                }

                .title {
                    margin-top: 22px;
                }
            }
        }
    }
}
