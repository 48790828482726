@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__searchPopup {
    width: 100%;
    position: fixed;
    padding-top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease-in-out;
    filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.15));

    &_container {
        max-width: 1360px;
        margin: auto;
        padding-top: 40px;

        &_box {
            @include searchBar;
        }
    }

    &--active {
        padding-top: 110px;
        pointer-events: auto;
        opacity: 1;
        z-index: 10;
        transition: all 0.2s ease-in-out;
    }

    @media only screen and (max-width: 1280px) {
        &_container {
            padding-top: 60px;
        }
    }

    @media only screen and (max-width: 1024px) {
        opacity: 1;
        padding-top: 65px;
        pointer-events: auto;
        z-index: 10;

        &_container {
            padding-top: 0px;

            &_box {
                height: 45px;

                input {
                    font-size: 18px;
                    margin-top: 0;

                    &::placeholder {
                        font-size: 18px;
                        padding: 0 15px;
                        text-align: left;
                    }
                }

                button {
                    padding: 8px 30px 8px 10px;

                    img {
                        display: block;
                        height: auto!important;
                        width: 24px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 720px) {
        &_container {
            &_box {
                input {
                    font-size: 16px;

                    &::placeholder {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 560px) {
        &_container {
            &_box {
                height: 35px;

                input {
                    font-size: 16px;
                    height: 35px;
                    line-height: 35px;
                    padding: 0 2px 0 0;
                    text-align: left;

                    &:valid {
                        padding: 0 2px 0 15px;
                        text-align: left;
                    }

                    &::placeholder {
                        font-size: 12px;
                        padding: 0;
                    }
                }

                button {
                    padding: 8px 5px;

                    img {
                        width: 20px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 370px) {
        &_container {
            &_box {
                input {
                    &::placeholder {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
