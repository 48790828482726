.section-cart.checkout {
    padding: 50px 0;

    .summary {
        background: $sega-white;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin: 20px 0;
        padding: 15px;
        width: 100%;

        h2 {
            border-bottom: 1px solid $sega-light-blue;
            margin: 0 0 15px 0;
            padding-bottom: 15px;
        }

        .summary-row {
            align-items: center;
            color: $sega-black;
            display: flex;
            justify-content: flex-start;
            margin: 8px 0;
            text-transform: uppercase;

            .prices {
                margin-left: auto;
            }
        }

        .summary-price {
            border-top: 1px solid $sega-light-blue;
            margin-left: auto;
            width: 50%;

            .row {
                align-items: center;
                display: flex;
                margin: 15px 0;

                h3 {
                    text-transform: uppercase;
                }

                small {
                    color: $sega-light-blue;
                    display: inline-block;
                    font-size: 10px;
                    padding: 0 5px;
                    text-transform: uppercase;
                }

                .sum-price {
                    color: $sega-black;
                    margin-left: auto;
                }
            }

            .button-blue {
                display: block;
                margin-left: auto;
                text-align: center;
                width: 120px;
            }
        }
    }

    .addresses {
        h2 {
            text-transform: uppercase;
            margin-bottom: 5px
        }

        .info {
            display: block;
            margin: 5px 0 10px 0;
        }

        .delivery-address {
            column-gap: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 20px;

            .single-address {
                box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                color: $sega-black;
                margin: 0;
                overflow: hidden;

                &.empty {
                    align-items: center;
                    background: $sega-white;
                    color: $sega-black;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 18px;
                    padding: 15px 0;
                    text-align: center;
                    text-transform: uppercase;

                    em {
                        display: block;
                        font-size: 40px;
                        line-height: 25px;
                    }

                    &:hover {
                        background: $sega-blue;
                        color: $sega-white;
                    }
                }

                .container {
                    margin: 0;
                    padding: 0;

                    .title {
                        background: $sega-white;
                        padding: 15px;
                        width: 100%;
                    }
                }

                &.sega__enquiry_container_box_flex_check_list_item {
                    .container {
                        input:checked {
                            background: $sega-blue;

                            & ~ .title {
                                background: $sega-blue;
                                color: $sega-white;
                            }

                            & ~ .checkMark {
                                border: 2px solid $sega-white;
        
                                &:before {
                                    border: 2px solid $sega-blue;
                                    border-width: 0 7px 3px 0;
                                }
        
                                &:after {
                                    border: 2px solid $sega-white;
                                    border-width: 0 3px 3px 0;
                                }
                            }
                        }
                    }
                }

                .checkMark {
                    left: inherit;
                    right: 10px;
                    top: 6px;
                }

                .title {
                    font-size: 16px;
                }
            }
        }
    }

    .bold-text {
        display: block;
    }

    .highlighted {
        color: $sega-red;
        font-weight: 700;
    }

    .billing-address {
        h2 {
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        span {
            color: $sega-black;
        }
    }

    .button-green {
        display: inline-block;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 640px) {
    .section-cart.checkout {
        .summary .summary-price {
            max-width: 400px;
            width: 100%;
        }
    
        .addresses .delivery-address {
            column-gap: 0px;
            row-gap: 20px;
            grid-template-columns: repeat(1,1fr);
        }
    }
}

@media only screen and (max-width: 560px) {
    .section-cart {
        .cart-header h1 {
            font-size: 2em;
        }

        &.checkout .summary .summary-price .row {
            flex-wrap: wrap;

            .sum-price {
                text-align: right;
                width: 100%;
            }
        }
    }
}