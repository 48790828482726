@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__articles {
    width: 100%;
    margin-top: 20px;

    &_container {
        max-width: 980px;
        margin: auto;

        &_left {
            margin: 40px 0;
            display: flex;

            .block {
                margin-left: 30px;
            }

            img {
                max-width: 455px;
                height: max-content;
                box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.45);
            }
        }

        &_right {
            margin: 40px 0;
            display: flex;

            .block {
                margin-right: 30px;
            }

            img {
                margin-left: auto;
                max-width: 455px;
                height: max-content;
                box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.45);
            }
        }

        .block {
            margin-top: auto;
            margin-bottom: auto;
            display: block;
            text-align: justify;

            h4 {
                font-size: 18px;
                text-transform: uppercase;
                margin-bottom: 5px;
                font-weight: 800;
                font-family: 'futura-pt-bold', sans-serif;
                text-align: left;
            }
        }

        .marginTop {
            margin-top: 40px;
        }

        &_table {
            width: 100%;

            &_row {
                display: flex;
                border-bottom: 1px solid $sega-blue;
                margin-bottom: 15px;

                &:last-child {
                    border-bottom: none;
                }

                &_col {
                    width: 33%;
                    text-align: left;

                    &:nth-child(2) {
                        text-align: center;
                    }

                    &:last-child {
                        text-align: end;
                    }

                    h3 {
                        color: $sega-blue;
                        text-transform: uppercase;
                    }

                    span {
                        font-size: 18px;
                        font-weight: 100;
                        color: $sega-black;
                    }

                    a {
                        line-height: 31px;
                        margin-left: auto;
                        margin-bottom: 8px;
                        margin-top: -6px;
                        padding: 0 15px;
                        width: fit-content;

                        &.button_outline:after {
                            margin-top: -3px;
                        }
                    }
                }
            }
        }

        &_date {
            color: $sega-blue;
            font-size: 14px;
            text-align: right;
            padding: 0 15px 10px 15px;
        }

        &_description {

            img {
                max-width: 100%;
                margin: auto;
                display: block;
                height: auto!important;
            }

            h2, h3 {
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            h2 {
                font-size: 40px;
                font-weight: 800;
                font-family: 'futura-pt-bold', sans-serif;
                margin: 0;
                margin-bottom: 15px;
            }

            p, ul {
                margin-bottom: 20px;
                color: $sega-black;
            }

            ul {

                li {
                    list-style: disc;
                    margin-left: 20px;
                    margin-bottom: 5px;
                }
            }

            a {
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 700;
                text-decoration: underline;

                &:active,
                &:focus,
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &_video {
            margin-top: 15px;

            iframe, video {
                padding: 0 10px;
                max-width: 1000px;
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 1020px) {

        &_container {

            &_left, &_right {
                padding: 0 15px;
            }

            &_description {
                padding: 0 15px;

                h2 {
                    text-align: center;
                }
            }

            &_table {
                padding: 0 15px;
            }
        }
    }

    @media only screen and (max-width: 870px) {

        &_container {

            &_left, &_right {
                align-items: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;

                .block {
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 25px;
                    order: 1;
                }

                img {
                    order: 0;
                    margin: 0 auto 20px auto;
                    width: 100%;
                    height: auto!important;
                }
            }

            &_video {

                iframe {
                    padding: 0 15px;
                    height: 390px;
                }
            }

            &_description {
                img {
                    float: none!important;
                    margin: 10px auto 20px auto!important;
                    max-width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 650px) {
        &_container {
            &_table {
                tr {
                    display: inline-grid;
                    justify-content: center;
                    width: 100%;

                    th {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 5px;

                        &:nth-child(2) {
                            width: 100%;
                            margin: 0 auto 10px;
                        }

                        &:nth-child(3) {
                            width: 100%;
                            margin: 0 auto 10px;
                        }
                    }

                    a {
                        margin: 0 auto
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 540px) {

        &_container {

            &_video {

                iframe {
                    height: 250px;
                }
            }

            &_description {

            }
        }
    }
}

.sega__news_games {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px auto;
    max-width: 1000px;
    width: 100%;
}

@media only screen and (max-width: 650px) {
    .sega__articles_container_table_row_col a {
        margin: 0 auto!important;
    }
}
