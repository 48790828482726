@import "../../variables/colors";
@import "../../variables/keyframes";

.sega__loginPopup {
    display: none;

    &--active {
        display: block !important;
    }

    .sega__loginPopup_login {
        top: 0;
        position: fixed;
        width: 100vw;
        margin-top: -20px;
        height: 105vh;
        z-index: 99999;
        animation: opacity 0.7s;
        background-color: rgba(3, 27, 34, 0.16);
        background-image: url('/assets/img/sega_slider_bag.png');

        &_container {
            width: 610px;
            height: 350px;
            border-radius: 10px;
            background-color: $sega-grey;
            margin: 33vh auto;
            padding: 40px;

            &:hover {

                .closeLoginPopup {
                    opacity: 1;
                    transition: all 0.5s ease-in-out;
                }
            }

            .closeLoginPopup {
                cursor: pointer;
                transition: all 0.5s ease-in-out;
                opacity: 0;
                border: none;
                margin-left: 95%;
                font-size: 28px;
                padding: 0;
                margin-top: -20px;
                margin-bottom: -25px;
                color: $sega-red;
                outline: none;
                background: transparent;

                &:hover {
                    color: rgba($sega-red, 0.5);
                }
            }

            .overlay {
                display: none;
            }

            &_form {
                text-align: center;

                h3 {
                    font-size: 33px;
                    text-transform: uppercase;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 800;
                    color: $sega-blue;
                    margin-bottom: 20px;
                }

                input {
                    width: 100%;
                    padding: 8px 10px 8px 30px;
                    margin: 10px 0;
                    box-shadow: 0 0 15px 2px rgba(0,0,0,0.10);

                    &::placeholder {
                        color: $sega-light-blue;
                        padding-left: -20px;
                        margin-left: -20px;
                        left: -20px;
                    }
                }

                input:focus {
                    padding-left: 30px;
                }

                input:valid {
                    padding-left: 30px;
                }

                button {
                    text-transform: uppercase;
                    font-size: 20px;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 800;
                    padding: 7px 5px;
                    margin-top: 7px;
                }

                h4 {
                    margin-top: 27px;
                    font-size: 14px;
                    font-weight: 100;
                    color: $sega-light-blue;

                    a {
                        color: $sega-red;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 700px) {

        .sega__loginPopup_login {

            &_container {
                width: 100%;
                border-radius: 0;
                height: auto;

                .closeLoginPopup {
                    opacity: 1;
                    margin-left: 90%;
                    margin-bottom: 10px;
                }

                &_form {

                    h3 {
                        font-size: 37px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 450px) {

        .sega__loginPopup_login {

            &_container {
                width: 100%;
                border-radius: 0;
                height: auto;

                .closeLoginPopup {
                    opacity: 1;
                    margin-left: 0;
                    margin-bottom: 10px;
                }

                &_form {

                    h3 {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}

.sega__loginPage {
    &_form_container {
        margin: 0 auto;
        max-width: 500px;
        text-align: center;
        width: 100%;

        input {
            width: 100%;
            padding: 8px 10px 8px 30px;
            margin: 10px 0;
            box-shadow: 0 0 15px 2px rgba(0,0,0,0.10);

            &::placeholder {
                color: $sega-light-blue;
                padding-left: -20px;
                margin-left: -20px;
                left: -20px;
            }
        }

        input:focus {
            padding-left: 30px;
        }

        input:valid {
            padding-left: 30px;
        }

        button {
            text-transform: uppercase;
            font-size: 20px;
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: 800;
            padding: 2px 5px;
            margin: 10px 0 30px 0;
            max-width: 300px;
            width: 100%;
        }

        .text-bottom {
            color: $sega-light-blue;

            a {
                color: $sega-red;
            }
        }
    }
}
