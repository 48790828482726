@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";


.sega__productsBasket {

    &_content {
        display: flex;
        max-width: 1045px;
        padding: 0 15px;
        width: 100%;
        color: $sega-blue;
        margin: 30px auto 10px auto;

        &_products {
            margin: 20px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &_product {
                margin: 5px auto;
                background-repeat: no-repeat;
                transition: all 0.2s ease-in-out;
                padding: 10px;
                width: 327px;
                filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.2));

                a {
                    padding: 0;

                    img {
                        background: $sega-white;
                        height: 155px!important;
                        object-fit: contain;
                        width: 305px;
                    }
                }

                &_basket {
                    width: 305px;
                    background-color: $sega-white;
                    margin-top: -13px;
                    padding: 14px 10px;
                    height: auto;
                    display: block;

                    p {
                        font-size: 13px;
                        margin-top: 4px;
                        margin-bottom: -11px;
                        color: $sega-light-blue;
                    }

                    h3 {
                        text-transform: uppercase;
                        color: $sega-blue;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                        width: 100%;
                        margin-bottom: 10px;
                        border-bottom: 1px solid $sega-blue;
                        padding-bottom: 10px;
                        font-size: 17px;
                    }

                    &_form {
                        display: flex;
                        justify-content: space-between;

                        h2 {
                            font-size: 20px;
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 900;
                            margin: 0;
                        }

                        .basket-add {
                            justify-content: space-between;
                            display: contents;

                            label {
                                display: flex;
                                margin: 0;

                                span {
                                    text-transform: uppercase;
                                    font-weight: 800;
                                    font-family: 'futura-pt-bold', sans-serif;
                                    font-size: 18px;
                                    display: block;
                                }

                                input {
                                    border: 1px solid $sega-dark-grey;
                                    border-radius: 0;
                                    width: 28px;
                                    padding-left: 8px;
                                    height: 24px;
                                    margin-left: 6px;
                                    margin-top: -1px;
                                }
                            }

                            button {
                                cursor: pointer;
                                display: flex;
                                font-size: 17px;
                                font-weight: 900;
                                font-family: 'futura-pt-bold', sans-serif;
                                text-transform: uppercase;
                                color: $sega-blue;
                                outline: none;
                                border: 2px solid $sega-blue;
                                background-color: transparent;
                                margin-bottom: auto;
                                margin-top: auto;
                                transition: all 0.2s ease-in-out;
                                box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0);
                                transform: scale(1);

                                &:hover {
                                    transition: all 0.2s ease-in-out;
                                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
                                }
                                &:active {
                                    transition: all 0.2s ease-in-out;
                                    transform: scale(0.9);
                                }

                                .add-button {
                                    font-size: 17px !important;
                                    display: inline-block;

                                    span {
                                        font-size: 16px;
                                    }
                                }
                                img {
                                    width: 16px;
                                }
                            }
                        }
                    }
                }

                &_info, &_infoWhite, &_infoResult {

                    .info-title {
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .info-date {
                        margin: 10px 0;
                        color: $sega-black;
                    }

                    .info-text {
                        color: $sega-black;
                    }

                    .button_outline {
                        border: none;
                        box-shadow: none;
                        background-color: transparent;
                        padding: 0;
                        margin-top: 24px;
                    }
                }

                &_infoResult {
                   display: flex;
                    padding: 10px;
                    background: $sega-white;
                    filter: drop-shadow(2px 2px 2px rgba($sega-black, 0.1));

                   img {
                       width: auto !important;
                   }

                    .result-box {
                        margin: auto 10px;

                        h3 {
                            margin: 2px 0;
                        }

                        h3, p {
                            font-size: 14px;
                        }

                        text-transform: uppercase;
                    }
                }

                &_infoWhite {
                    background-color: $sega-white;
                    padding: 15px 10px;
                    margin-top: -12px;
                    width: 223px;

                    .info-title {
                        font-size: 13px;
                        text-transform: uppercase;
                    }

                    .button_outline {
                        opacity: 0.8;
                        font-size: 13px;

                        &:after {
                            font-size: 22px;
                        }
                    }
                }

                &.news {
                    margin-bottom: 30px;
                    
                    .sega__productsBasket_content_products_product_image {
                        display: block;
                        height: auto;
                        margin: 0 0 30px 0;
                        padding-bottom: 100%;
                        position: relative;
                        width: 100%;

                        img {
                            background: $sega-white;
                            height: 100%!important;
                            object-fit: cover;
                            position: absolute;
                            width: 100%;
                        }
                    }
                }
            }

            &.three-columns {
                .sega__productsBasket_content_products_product {
                    width: calc(100% / 3);

                    a {
                        display: block;
                        margin: 0;
                        width: 100%;
                    }

                    &_basket {
                        margin-top: -5px;
                        width: 100%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 700px) {
        &_content {
            &_products {
                justify-content: center;

                &.three-columns {
                    .sega__productsBasket_content_products_product {
                        width: 50%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 450px) {
        &_content {
            &_products {
                &.three-columns {
                    .sega__productsBasket_content_products_product {
                        max-width: 300px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.searching {
    margin: 0 auto;

    .sega__productsBasket_content_products {
        display: none;
    }

    h3 {
        opacity: 0;
    }

    .searching-ellipsis {
        display: block !important;
    }
}
