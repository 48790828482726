@import "../variables/colors";
@import "../variables/keyframes";

.sega__breadcrumbs {    
    &_container {
        align-items: center;
        max-width: 1000px;
        margin: 20px auto;
        padding: 0 20px;
        display: flex;

        button {
            align-items: center;
            display: flex;
            border: none;
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: 600;
            background-color: transparent;
            cursor: pointer;
            transition: 0.2s ease-in-out;

            span {
                display: block;
                color: $sega-blue;
                text-transform: capitalize;
            }

            &:before {
                margin-right: 6px;
                transform: rotate(180deg);
                display: block;
                color: $sega-blue;
                content: '»';
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 900;
                font-size: 28px;
                height: 20px;
                line-height: .45;
            }

            &:hover {
                span {
                    transition: 0.2s ease-in-out;
                    color: rgba($sega-red, 0.8);
                }

                &:before {
                    transition: 0.2s ease-in-out;
                    color: rgba($sega-red, 0.8);
                }
            }
        }

        &_list {
            display: flex;
            margin-left: 25px;
            list-style: none;

            & > ul {
                margin-left: 0;
            }

            &_item {
                display: flex;
                list-style: none;

                &:before {
                    display: block;
                    margin: 0 6px;
                    content: '/';
                }

                &:last-child {
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 600;
                }

                a {
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transition: 0.2s ease-in-out;
                        color: rgba($sega-red, 0.8);
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 800px) {
        &_container {
            font-size: 14px;
            padding: 0 15px;

            & > a {
                display: none;
            }

            &_list {
                flex-wrap: wrap;
                margin-left: 0;

                &_item {
                    justify-content: center;
                    margin: 0;

                    &:first-child {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
