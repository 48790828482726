@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega_technical {
    margin-bottom: 50px;

    &_container {
        max-width: 980px;
        margin: 30px auto;
        text-align: center;

        h2 {
            text-transform: uppercase;
            font-size: 45px;
            font-family: 'futura-pt-bold', sans-serif;
            font-weight: 900;
            padding-bottom: 15px;
            border-bottom: 1px solid;
            border-color: $sega-blue;
            max-width: 400px;
            width: 100%;
            margin: 0 auto 20px auto;
        }

        img {
            margin-top: 40px;
            width: 100%;
            padding: 0 15px;
        }

        &_list {
            list-style: none!important;
            padding-left: 0!important;
            li {
                align-items: center;
                display: flex;
                justify-content: center;
                margin-bottom: 10px!important;

                img {
                    display: block;
                    height: 32px!important;
                    margin-right: 15px;
                    width: 32px;
                }

                a {
                    text-transform: uppercase;
                    font-size: 30px;
                }
            }
        }
    }
    @media only screen and (max-width: 520px) {

        &_container {

            h2 {
                font-size: 35px;
            }

            &_list {

                li {

                    img {
                        width: 25px;
                        margin-top: -1px;
                        margin-right: 7px;
                    }

                    a {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
