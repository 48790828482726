@import "../variables/colors";

button,
.button {
    &:disabled {
        filter: grayscale(1);
        opacity: 0.4;
        pointer-events: none;
    }
}

input[type="submit"] {
    &:disabled {
        filter: grayscale(1);
        opacity: 0.4;
        pointer-events: none;
    }
}

.input__button_red {

  button {
    background-color: $sega-red;
    outline: none;
    border: 1px solid $sega-red;
    border-radius: 20px;
    color: $sega-white;
    width: 50%;
    padding: 8px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    opacity: 1;
  }

  &:hover {

    button {
      transition: 0.2s ease-in-out;
      opacity: 0.8;
      background-color: #13497e;
      border: 1px solid $sega-blue;

      &:active {
       transform: scale(0.95);
      }
    }
  }
}

.input__button_white {

    button {
        background-color: $sega-white;
        outline: none;
        border: 1px solid $sega-blue;
        border-radius: 20px;
        color: $sega-blue;
        width: 50%;
        padding: 8px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        opacity: 1;
    }

    &:hover {

        button {
            transition: 0.2s ease-in-out;
            opacity: 0.8;
            border: 1px solid $sega-red;
            color: $sega-red;

            &:active {
                transform: scale(0.95);
            }
        }
    }
}

.input__button_blue {

    button {
        background-color: $sega-blue;
        outline: none;
        border: 1px solid $sega-white;
        border-radius: 20px;
        color: $sega-white;
        width: 100%;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        text-transform: uppercase;
        font-family: 'futura-pt-bold', sans-serif;
        font-weight: 600;
        padding: 12px;
        opacity: 1;
    }

    &:hover {

        button {
            transition: 0.2s ease-in-out;
            opacity: 0.8;
            background-color: $sega-red;

            &:active {
                transform: scale(0.95);
            }
        }
    }
}

.button_outline {
    border-radius: 20px;
    display: flex;
    outline: none;
    text-transform: uppercase;
    border: 2px solid $sega-blue;
    color: $sega-blue;
    font-family: 'futura-pt-bold', sans-serif;
    font-weight: 800;
    padding: 5px 15px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:after {
        margin-left: 8px;
        margin-top: -8px;
        content: '»';
        display: block;
        font-size: 25px;
    }

    &:hover {
        transition: all 0.2s ease-in-out;
        border: 2px solid $sega-red;
        color: $sega-red;
    }
}

.news-button {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 30px 0 10px 0;

    a {
        background: $sega-red;
        border-radius: 20px;
        border: 2px solid $sega-red;
        color: $sega-white;
        cursor: pointer;
        display: inline-block;
        font-family: 'futura-pt-bold', sans-serif;
        font-weight: 800;
        outline: none;
        padding: 8px 15px;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;

        &:hover {
            background: transparent;
            color: $sega-red
        }
    }
}

.button-blue,
.button-red,
.button-green,
.button-blue-outline {
    border-radius: 20px;
    color: $sega-white;
    cursor: pointer;
    font-size: 22px;
    font-family: 'futura-pt-bold', sans-serif;
    line-height: 1.2;
    padding: 3px 25px;
    opacity: 1;
    outline: none;
    transition: 0.2s ease-in-out;
    text-transform: uppercase;

    &:hover {
        opacity: 0.8;
    }

    &.small {
        font-size: 14px;
        padding: 3px 15px;
    }

    &.arrow {
        font-size: 18px;
        position: relative;
        padding: 3px 15px;

        &:after {
            content: '»';
            color: $sega-white;
            display: inline;
            font-size: 25px;
            font-weight: 100;
            pointer-events: none;
            position: relative;
            padding-left: 10px;
            line-height: 0.5;
            transform: rotate(0deg);
            transition: all .15s ease-in-out;
        }
    }
}

.button-blue {
    background-color: $sega-blue;
    border: 1px solid $sega-blue;
}

.button-red {
    background-color: $sega-red;
    border: 1px solid $sega-red;
}

.button-green {
    background-color: $sega-green;
    border: 1px solid $sega-green;
}

.button-blue-outline {
    background-color: transparent;
    border: 1px solid $sega-blue;
    color: $sega-blue;

    &.arrow::after {
        color: $sega-blue;
    }
}

.ss-buttons-bar {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    .button-box {
        width: 50%;
    }

    .sega-account__content-menu-item {
        width: calc(100% - 20px);

        .icon {
            height: 80px;
            flex-shrink: 0;
            margin-right: 40px;
            position: relative;
            width: 80px;

            img {
                display: block;
                height: 100%!important;
                object-fit: contain;
                width: 100%;
            }
        }

        .error-message {
            font-family: 'futura-pt', sans-serif;
            font-weight: 400;
            font-size: 14px;
        }
    }
}

#warranty-form {
    .buttons-row .btn {
        margin: 10px auto;
        max-width: 240px;
        width: 100%;
    }

    .form-subtitle {
        text-transform: uppercase;
    }

    .info {
        display: inline-block;
        margin: 3px 0 5px 0;
    }
}

@media only screen and (max-width: 720px) {
    .ss-buttons-bar {
        .button-box {
            min-height: 140px;
            width: 100%;
        }
    }
}
