//colors
$sega-blue: #083a7e;
$sega-blue-light: #3c6599;
$sega-sky-blue: #489cea;
$sega-light-blue: #819bd1;
$sega-red: #FF0000;
$sega-black: #000000;
$sega-dark-grey: #8d8d8d;
$sega-grey: #f5f5f5;
$sega-dark-grey: #e9e9e9;
$sega-white: #ffffff;
$sega-green: #03881f;
