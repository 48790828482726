@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__productsTab_content_closeFilters {
    margin-top: 40px;
}

.sega__productsTab {

    &_panel {
        background: $sega-white;
        border-top: 2.5px solid $sega-white;
        border-bottom: 2.5px solid $sega-white;
        box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
        left: 50%;
        margin-top: 30px;
        margin-bottom: 20px;
        position: relative;
        transform: translateX(-50%);
        width: 100vw;
        z-index: 1;

        &:before,
        &:after {
            background: $sega-grey;
            content: '';
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &::before {
            top: 0px
        }

        &::after {
            bottom: 0px
        }

        &_title {
            margin: 0 auto;
            max-width: 1020px;
            padding: 30px 15px 0 15px;
        }


        &_boxes {
            margin: auto;
            flex-wrap: wrap;

            &_text {
                @include productText;
                color: $sega-black;
                margin: 0 auto;
                text-align: left;
                width: 100%;

                p {
                    text-align: left;

                    a {
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                ul {
                    list-style: disc;
                    margin: 30px 0;
                    padding-left: 20px;

                    li {
                        margin: 5px 0;
                        text-align: left;
                    }

                    ul {
                        list-style: circle;
                        margin: 0;
                        padding-left: 25px;
                    }
                }

                h3 {
                    text-align: center;
                }
            }

            &_blueBar {
                background-color: $sega-blue;
                padding: 0 20px;
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;

                &_container {
                    display: flex;
                    flex-wrap: wrap;
                    margin: auto;
                    justify-content: center;

                    &_box {
                        display: flex;
                        max-width: 47%;
                        min-width: 520px;
                        width: 100%;
                        margin: 20px auto;

                        img {
                            object-fit: contain;
                        }

                        p {
                            margin-top: auto;
                            margin-bottom: auto;
                            margin-left: 20px;
                            color: $sega-white;

                            span {
                                font-family: 'futura-pt-bold', sans-serif;
                                font-weight: 800;
                            }
                        }
                    }
                }
            }
        }

        &_box, &_boxes {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 1020px;
            padding: 15px;

            &_buttons {
                display: none;
            }

            &_search {
                margin-left: -20px;
                padding: 20px;
                justify-content: center;
                max-width: 440px;
                width: 100%;
                display: flex;
                position: relative;

                input {
                    height: 42px;
                    padding: 10px 45px 10px 20px;
                    width: 100%;
                    border: 2px solid $sega-dark-grey;
                }

                button {
                    border: none;
                    background: 0 0;
                    cursor: pointer;
                    height: 42px;
                    margin: 0px;
                    position: absolute;
                    right: 30px;
                    transition: .2s ease-in-out;
                    width: 42px;

                    img {
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                    }
                }
            }

            &_sort {
                align-self: center;
                margin-left: -43px;
                width: 160px;
                @include niceSelect;

                .select-single .select2.select2-container {
                    box-shadow: none;
                    border: 2px solid #dbdbdb;

                    .select2-selection--single .select2-selection__rendered {
                        padding: 3px 15px;
                    }

                    .select2-selection__arrow {
                        top: 3px;
                    }
                }
            }

            &_content {
                margin: 15px auto 15px 15px;
                width: 100%;
                max-width: 320px;

                &:first-child {
                    margin: 15px auto;
                }

                &_head {
                    display: flex;
                    padding: 0 10px;

                    img {
                        max-width: 31px;
                        padding-top: 2px;
                    }

                    h3 {
                        white-space: nowrap;
                        margin-left: 10px;
                        font-size: 34px;
                        text-transform: uppercase;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                    }
                }

                &_info {
                    padding-left: 15px;

                    h4 {
                        text-transform: uppercase;
                        color: $sega-black;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 800;
                        margin-bottom: 5px;
                        font-size: 15px;
                    }

                    li {
                        margin: 20px 0;

                        a {
                            color: $sega-blue;
                        }

                        &:first-child {
                            margin: 0;
                        }
                    }

                    address {
                        font-style: normal;

                        p {
                            color: $sega-black;
                        }
                    }
                }
            }
        }

        &_form {
            display: block;
            width: 100%;
            justify-content: space-around;
            border-bottom: 2px solid $sega-grey;
            max-width: 980px;
            margin: auto;
            flex-wrap: wrap;

            &_text {
                @include productText;
            }

            &_box {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &_select {
                    width: 48%;
                    @include niceSelect;

                    .select2.select2-container {
                        margin-top: 15px;
                    }
                }

                .container {
                    width: 48%;
                    margin-bottom: 20px;

                    label {
                        font-size: 18px;
                        color: $sega-blue;
                        margin-bottom: 10px;
                        text-transform: capitalize;
                        display: flex;

                        span {
                            font-size: 22px;
                            margin-left: 5px;
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 800;
                        }

                        .light-text {
                            font-weight: 100;
                            font-size: 14px;
                        }
                    }

                    input {
                        @include inputText
                    }

                    .file-input {
                        width: auto;
                        height: auto;
                        background-color: #55a1fb;
                        border: 1px solid $sega-blue;
                    }

                    .button-file {
                        border-radius: 2px;
                        outline: 0;
                        border: 1px solid $sega-blue;
                        background: #e5e9f2;
                        color: $sega-blue;
                        padding: 3px 9px;
                        margin-top: 5px;
                        position: relative;
                        width: fit-content;
                        cursor: pointer;
                        display: initial;

                        input {
                            display: inline-block;
                            position: absolute;
                            z-index: 1;
                            width: 100%;
                            height: 50px;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            cursor: pointer;
                        }
                    }

                    .text-file {
                        display: contents;

                        span {
                            margin-left: 10px;
                        }
                    }

                    .label-file {
                        margin-bottom: 20px;
                    }
                }

                .input__button_red {
                    padding-bottom: 30px;
                    margin-top: 90px;

                    button {
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 800;
                        font-size: 20px;
                        text-transform: uppercase;
                    }
                }

                p {
                    margin-top: 10px;
                    font-size: 14.5px;

                    a {
                        margin-left: 5px;
                        color: #f03636;
                    }
                }

                .container-check {
                    width: 100%;
                    @include segaCheckbox;

                    .container {
                        margin-top: 10px;
                    }
                }

                @include segaSelect;
            }
        }
    }

    &_content {
        display: flex;
        max-width: 1020px;
        padding: 0 15px;
        width: 100%;
        color: $sega-blue;
        margin: 30px auto 0 auto;

        &_openFilters {
            align-items: center;
            display: none;
            height: 47px;
            line-height: 45px;
            padding: 5px 20px;
            position: relative;
            width: 100%;

            &:after {
                margin: 0;
                position: absolute;
                pointer-events: none;
                right: 20px;
                top: 50%;
                transform: translateY(-50%) rotate(90deg)
            }
        }

        &_closeFilters-wrapper {
            display: none;
        }

        &_menu {
            min-width: 260px;
            max-width: 240px;
            padding-right: 20px;
            width: 100%;

            h2 {
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 900;
                margin-bottom: 10px;
                text-transform: uppercase;
                font-size: 18px;
            }

            a, label {
                text-transform: capitalize;
            }

            &_box {

                &_items {

                    &_item {
                        display: flex;
                        border-bottom: 1px solid;
                        border-color: $sega-blue;

                        a {
                            text-transform: uppercase;
                            cursor: pointer;
                            font-size: 16px;
                            display: block;
                            margin: 10px 0;
                            width: 100%;
                        }

                        &:first-child {
                            border-top: 1px solid;
                        }

                        &:after {
                            content: '»';
                            color: $sega-blue;
                            font-size: 26px;
                            font-weight: 100;
                            pointer-events: none;
                            transition: all 0.15s ease-in-out;
                            margin-left: auto;
                            align-items: center;
                            padding-left: 20px;
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                    }
                }

                li.hide {
                    display: none;
                }
            }

            &_clear {
                margin-bottom: 20px;

                h2 {
                    cursor: pointer;

                    &:after {
                        transform: rotate(90deg) scale(1);
                        color: $sega-blue;
                        display: inline-block;
                        content: '»';
                        font-size: 26px;
                        font-weight: 100;
                        margin-left: 10px;
                        transition: all 0.2s ease-in-out;
                    }
                }

                .active {

                    &:after {
                        transform: rotate(270deg) scale(1);
                        color: $sega-red;
                        margin-left: 10px;
                        transition: all 0.2s ease-in-out;
                    }
                }

                .clear-menu {
                    opacity: 1;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        transition: 0.2s ease-in-out;
                        color: rgba($sega-red, 0.8);
                    }
                }
                .active {
                    transition: 0.2s ease-in-out;
                    opacity: 0;
                }
            }

            &_box {
                margin-top: 30px;
                padding-bottom: 30px;
                border-bottom: 1px solid;
                transition: 0.2s ease-in-out;
                opacity: 1;
                display: block;

                &:last-child {
                    border-bottom: none;
                }

                @include segaCheckbox;
            }

            .active {
                display: flex;
                transition: 0.2s ease-in-out;
                opacity: 0;
            }
        }

        .max-padding {
            padding-right: 35px;
        }

        &_products {
            @include containerPanelTab;
            width: 100%;

            #cat-description {
                padding: 10px;
            }

            h2 {
                &.excerpt,
                &.full {
                    font-size: 16px;
                    font-family: 'futura-pt', sans-serif;
                    font-weight: 400;
                    text-transform: none;
                }
            }

            #cat-additional-text {
                margin-bottom: 30px;

                .expand-cat-desc {
                    text-align: right;
                }
            }

            .expand-cat-desc {
                font-weight: 700;

                &:after {
                    color: $sega-blue;
                    display: inline-block;
                    content: '»';
                    font-size: 22px;
                    margin-left: 5px;
                    position: relative;
                    top: -1px;
                    transition: all .2s ease-in-out;
                }
            }

            &_container {
                display: flex;
                flex-wrap: wrap;
                @include containerPanelTab;

                .display-games {
                    justify-content: center;
                    width: 100%;

                    .sega__productsTab_content_products_product {
                        background-repeat: no-repeat;
                        background-color: $sega-white;
                        display: flex;
                        flex-direction: column;
                        margin: 10px;
                        transition: all 0.2s ease-in-out;
                        width: 222px;

                        &_name {
                            width: 222px;
                            background-color: $sega-white;
                            min-height: 50px;
                            display: inline-flex;
                            justify-content: center;

                            span {
                                border-top: 1px solid rgba($sega-blue, 0.5);
                                color: $sega-blue;
                                font-family: 'futura-pt-bold', sans-serif;
                                font-weight: 900;
                                font-size: 12px;
                                height: 100%;
                                margin-top: auto;
                                margin-bottom: 0;
                                padding: 10px 0;
                                text-align: center;
                                text-transform: uppercase;
                                transition: all 0.2s ease-in-out;
                                width: 80%;
                            }
                        }

                        &:hover {
                            .sega__productsTab_content_products_product_name span {
                                color: $sega-red;
                            }
                        }
                    }
                }

                .no-results {
                    width: 100%;
                    text-align: center;
                    border-top: 1px solid $sega-blue;
                    margin-top: 15px;

                    h2 {
                        font-size: 29px;
                        color: $sega-red;
                    }

                    a {
                        font-size: 20px;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            transition: all 0.2s ease-in-out;
                            color: $sega-red;
                        }

                        &:after {
                            content: '»';
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 900;
                            font-size: 20px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    &_pagination {
        display: flex;
        font-family: 'futura-pt-bold', sans-serif;
        font-weight: 600;
        justify-content: center;
        max-width: 980px;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 50px;

        ul {
            display: flex;
            padding-right: 15px;
            flex-wrap: wrap;

            li {
                padding-left: 5px;
                margin-left: 7px;
                font-weight: 200;

                &:before {
                    display: inline-block;
                    content: '|';
                }

                &:last-child, &:first-child {

                    &:before {
                        display: none;
                    }
                }

                a {
                    transition: 0.2s ease-in-out;
                    margin-left: 7px;

                    &:hover {
                        transition: 0.2s ease-in-out;
                        color: rgba($sega-red, 0.8);
                    }
                }

                .active {
                    color: rgba($sega-red, 0.8);
                }
            }
        }
    }
    @media only screen and (max-width: 1032px) {

        &_content {

            &_products {
                justify-content: center;
            }
        }
    }
    @media only screen and (max-width: 1020px) {

        &_panel {

            &_boxes {

               &_text {
                   padding-left: 15px;
                   padding-right: 15px;

                   h3 {
                       white-space: normal;
                   }
               }
            }

            &_form {

                &_box {
                    display: block;
                    padding: 0 15px;

                    p {
                        text-align: center;
                    }

                    &_select {
                        width: 100%;
                        text-align: center;
                    }

                    .container {
                        width: 100%;

                        label {
                            justify-content: center;
                        }
                    }

                    .container-grid {
                        display: grid;

                        .button-file {
                            margin: auto;
                        }

                        .text-file {
                            margin: auto;

                            span {
                                text-align: center;
                                margin-left: 0;
                                margin-top: 10px;
                            }

                            & + .error-validation {
                                display: inline-block;
                                margin-top: 10px;
                                width: 100%;
                            }
                        }
                    }

                    .container-check {

                        .container {
                            width: 50%;
                            text-align: center;
                            margin: 20px auto;
                        }
                    }
                }
            }

            &_box {

               &_content {
                   text-align: center;
                   margin: 15px auto;

                   &_head {
                       justify-content: center;

                       h3 {
                           font-size: 30px;
                       }
                   }
               }
            }
        }
    }

    @media only screen and (max-width: 980px) {

        &_pagination {
            justify-content: center;
        }
    }

    @media only screen and (max-width: 850px) {

        &_panel {
            display: block;

            &_search {
                margin: auto;
            }

            &_boxes {
                padding-bottom: 30px;
            }

            &_box {
                display: flex;
                text-align: center;
                min-height: 70px;

                &_buttons {
                    justify-content: center;
                    display: flex;
                    width: 50%;

                    .panel-search, .panel-sort {
                        background-color: transparent;
                        text-transform: uppercase;
                        padding: 10px 20px 5px 20px;
                        margin: 20px 15px;
                        width: 100%;

                        span {
                            margin-top: 3px;
                            display: block;
                        }

                        &:after {
                            content: '»';
                            color: $sega-blue;
                            font-size: 30px;
                            font-weight: 100;
                            display: inline-block;
                            transform: rotate(90deg);
                            pointer-events: none;
                            transition: all 0.15s ease-in-out;
                            margin-left: auto;
                            align-items: center;
                            margin-top: -6px;
                        }

                        &--active {
                            border-color: $sega-red;
                            color: $sega-red;

                            &:after {
                                transform: rotate(275deg);
                                color: $sega-red;
                            }
                        }
                    }

                    .panel-search {
                        margin-left: 0;
                    }

                    .panel-sort {
                        margin-right: 0;
                    }
                }

                &_search {
                    display: none;
                    max-width: none;
                    margin-left: auto;
                    opacity: 0;

                    button {
                        margin-left: -60px;
                    }

                    &--active {
                        animation: openLeft 0.2s;
                        opacity: 1;
                        display: block;
                    }
                }

                &_sort {
                    .nice-select {
                        float: inherit;
                    }

                    .select-single .select2.select2-container {
                        border: 2px solid #1a3a7e;
                        border-radius: 25px;
                        height: 47px;

                        .select2-selection--single .select2-selection__rendered {
                            align-items: center;
                            height: 45px;
                        }

                        .select2-selection__arrow {
                            top: 9px;
                        }
                    }

                    &--active {
                        padding: 20px;
                        opacity: 1;
                        animation: opacity 1s;
                        display: block;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 800px) {
        &_panel {
            &_boxes {
                &_buttons {
                    width: calc((100% / 3) * 2);
                }
                &_sort {
                    margin-left: 30px;
                    width: calc(100% / 3);
                }
                &_blueBar {
                    &_container {
                        padding: 15px 0;

                        &_box {
                            min-width: fit-content;
                            display: block;
                            text-align: center;

                            img {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }

                &_text p img {
                    float: none!important;
                    margin: 10px auto 20px!important;
                    max-width: 100%;
                }
            }
        }

        &_content {
            display: block;

            .max-padding {
                padding-right: 0;
            }

            &_openFilters {
                display: flex;
                margin-bottom: 30px;
            }

            &_closeFilters {
                border: none;
                cursor: pointer;
                display: block;
                height: 40px;
                opacity: 0;
                pointer-events: none;
                position: fixed;
                right: 20px;
                top: 15px;
                text-decoration: none;
                width: 40px;

                &:before,
                &:after {
                    background: $sega-blue;
                    content: '';
                    height: 24px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transition: all 0.2s ease-in-out;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                    width: 2px;
                }

                &:after {
                    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                }

                &:hover {
                    &:before,
                    &:after {
                    background: $sega-red;
                    }
                }

                &.button_outline {
                    border: 2px solid $sega-red;
                    color: $sega-red;
                    font-size: 20px;
                    height: 42px;
                    left: 15px;
                    line-height: 38px;
                    padding: 0 20px;
                    text-align: center;
                    top: calc(100% - 60px);
                    width: calc(100% - 30px);
                    z-index: 2;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                .closeFilter-bg {
                    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(233,233,233,0.7) 8%, rgba(233,233,233,1) 25%);
                    bottom: 0;
                    content: '';
                    height: 80px;
                    left: 0;
                    position: fixed;
                    width: 100%;
                    z-index: 1;
                }
            }

            &_menu {
                background: $sega-dark-grey;
                display: none;
                height: 100%;
                left: 0;
                margin: auto;
                min-width: initial;
                max-width: initial;
                opacity: 0;
                overflow: auto;
                pointer-events: none;
                padding: 125px 0 80px 0;
                position: fixed;
                top: 0;
                transition: all .25s ease-in-out;
                width: 100%;
                z-index: 1000000;

                .person-menu {
                    padding-bottom: 0;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                &.is-active {
                    display: block;
                }

                &.is-showing {
                    opacity: 1;
                    pointer-events: auto;

                    .sega__productsTab_content_closeFilters {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                &_clear {
                    .open-menu {
                        display: none;
                    }

                    .clear-menu {
                        display: block;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                        font-size: 18px;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }

                &_box {
                    margin-top: 0;
                    text-align: center;
                    border-bottom: none;
                    border-top: 1px solid $sega-blue;
                    padding-bottom: 0;

                    &_items {
                        &_item:after {
                            display: none;
                        }
                    }

                    &_titleGame, &_titleManufactured, &_titleCategory, &_titleNo {
                        @include asideGameMenu;
                    }

                    &_listGame, &_listManufactured, &_listCategory, &_listNo {
                        @include asideGameList;
                        width: 250px;
                    }

                    .open {
                        display: block;
                        color: $sega-red;
                        li {
                            color: $sega-blue;

                        }
                        &:after {
                            transform: rotate(90deg) scale(1);
                            color: $sega-red;
                        }
                    }
                }
            }

            &_products {
                margin-top: 0;
                justify-content: center;
                text-align: center;

                h3 {
                    max-height: unset;
                }

                &_product {
                    &_name {
                        span {
                            font-size: 18px;
                        }
                    }
                }

                &_box {
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }

    @media only screen and (max-width: 560px) {
        &_content_menu {
            padding: 95px ​0 80px ;
        }
        &_panel {
            &_boxes {
                &_buttons {
                    .panel-search {
                        margin-right: 7px;
                    }
                    .panel-sort {
                        margin-left: 7px;
                    }
                }
                &_sort {
                    margin-left: 14px;
                }
            }
        }
    }

    @media only screen and (max-width: 500px) {

        &_panel {
            &_box {
                flex-wrap: wrap;
                &_buttons {
                    width: 100%;
                }
                &_sort {
                    margin-left: 0;
                    width: 100%;
                }
            }
            &_boxes {
                padding-bottom: 0;
                &_blueBar {

                    &_container {

                        &_box {

                            img {
                                width: 100%;
                                max-width: 265px;
                            }
                        }
                    }
                }
            }
        }

        &_content {

            &_menu {

                &_box {

                    &_titleGame, &_titleManufactured, &_titleCategory, &_titleNo {
                        font-size: 22px;
                    }

                    &_listGame, &_listManufactured, &_listCategory, &_listNo {

                        li {
                            font-size: 20px;
                            margin-bottom: 20px;

                            .checkMark {
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }

            &_products {

                &_product {

                    span {
                        font-size: 16px;
                    }
                }

                &_person_info_list_item {
                    a, span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
