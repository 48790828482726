@import "../variables/colors";
@import "../variables/keyframes";
@import "../variables/mixins";

.sega__header {
    background-color: $sega-white;
    box-shadow: 0 12px 21px -20px rgba(0,0,0,0.67);
    min-height: 93px;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 100;

    &-wrapper {
        top: 0px;
        position: fixed;
        transition: all .35s ease-in-out;
        z-index: 99999;
    }

    &_container {
        max-width: 1360px;
        margin: auto;

        &_top {
            padding-top: 10px;
            display: flex;
            font-size: 13px;
            width: max-content;
            margin: auto 15px 0 auto;

            &_more {
                margin-top: -10px;
                display: none;

                &_list {
                    @include menu;
                    list-style: none;

                    &_item {
                        font-weight: 200;
                        font-size: 11px;
                        margin-left: 15px;
                        list-style: none;

                        a, div, button {
                            transition: 0.2s ease-in-out;
                            font-weight: 200;
                            cursor: pointer;

                            img {
                                width: 20px;
                            }

                            span {
                                transition: 0.2s ease-in-out;
                                display: block;
                                margin-top: 3px;
                            }
                        }

                        &:hover {

                            a, div {

                                span {
                                    transition: 0.2s ease-in-out;
                                    color: $sega-red;
                                }
                            }
                        }

                        .has-items {
                            position: relative;

                            &:before {
                                background: $sega-red;
                                border-radius: 50%;
                                content: '';
                                display: block;
                                height: 8px;
                                left: 1px;
                                position: absolute;
                                top: 1px;
                                transition: all .4s ease-in-out;
                                width: 8px;
                            }
                        }
                    }
                }
            }

            &_contact {
                span {
                    align-items: center;
                    display: flex;
                    margin-left: auto;
                    width: max-content;

                    &.text {
                        display: inline;
                        margin: 0;
                        width: auto;
                    }

                    &.separator {
                        display: inline;
                        margin: 0;
                        width: auto;
                    }
                }

                a, div {
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 600;
                    transition: 0.2s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        transition: 0.2s ease-in-out;
                        color: rgba($sega-red, 0.8);
                    }
                }
            }

            &_list {
                display: flex;

                &_item {
                    margin-left: 20px;
                    transition: 0.2s ease-in-out;

                    a, div {
                        font-weight: 200;
                        cursor: pointer;
                    }

                    &:hover {
                        transition: 0.2s ease-in-out;

                        a, div {
                            transition: 0.2s ease-in-out;
                            color: rgba($sega-red, 0.8);
                        }
                    }
                }
            }
        }

        &_box {
            margin: 0 10px;
            display: flex;

            &_logo {
                display: block;
                flex-shrink: 0;
                height: auto;
                width: 190px;

                a {
                    display: block;
                    height: auto;
                    width: 100%;
                }

                img {
                    display: block;
                    height: auto!important;
                    filter: drop-shadow(0px 0px 0px rgba($sega-black, 0));
                    transition: 0.2s ease-in-out;
                    width: 100%;
                }
            }

            &_menu {
                margin-left: 10%;
                place-self: center;

                &_list {
                    @include menu;

                    &.only-mobile {
                        display: none;
                    }

                    &_item {
                        margin-left: 20px;
                        place-self: center;
                        font-size: 16px;
                        padding: 0px;
                        border-radius: 0;

                        a, div {
                            cursor: pointer;
                            display: block;
                            padding: 10px;
                            transition: 0.2s ease-in-out;
                            vertical-align: inherit;
                            z-index: 111;
                        }

                        &:hover {
                            a, div {
                                color: $sega-red;
                            }
                        }
                    }
                }
            }

            &_more {
                flex-shrink: 0;
                margin-left: 15px;
                place-self: center;

                &_list {
                    @include menu;
                    align-items: flex-end;

                    &_item {
                        font-weight: 200;
                        font-size: 11px;

                        img {
                            transition: 0.2s ease-in-out;
                        }
                        a, div, button {
                            margin-left: 12px;
                            transition: 0.2s ease-in-out;
                            font-weight: 200;
                            display: table;
                            cursor: pointer;

                            span {
                                transition: 0.2s ease-in-out;

                                &:last-child {
                                    display: block;
                                }
                            }
                        }

                        .has-items {
                            position: relative;

                            &:before {
                                background: $sega-red;
                                border-radius: 50%;
                                content: '';
                                display: block;
                                height: 10px;
                                left: calc(50% - 13px);
                                position: absolute;
                                top: 3px;
                                transition: all .4s ease-in-out;
                                width: 10px;
                            }
                        }

                        &:hover {

                            a, div {

                                span {
                                    transition: 0.2s ease-in-out;
                                    color: $sega-red;
                                }
                            }
                        }
                    }
                }
            }
        }

        &_hamburger {
            display: none;
            font-size: 11px;
            font-weight: 700;
            margin-left: 15px;
            position: relative;
            transition: all 0.2s ease;
            text-transform: uppercase;

            &_line {
                background-color: transparent;
                border-style: none;
                cursor: pointer;
                height: 24px;
                margin: 0 auto;
                outline: none;
                position: relative;
                padding: 0;
                transition: all 0.2s ease;
                width: 28px;

                span {
                    background-color: $sega-blue;
                    border-radius: 2px;
                    height: 3px;
                    display: block;
                    position: absolute;
                    transition: all 0.2s ease;
                    width: 100%;

                    &:nth-child(1) {
                        top: 0;
                        left: 0;
                        animation: hamburgerSpan1_close .3s forwards cubic-bezier(.8, .5, .2, 1.4);
                    }

                    &:nth-child(2) {
                        top: 8px;
                        right: 0;
                        animation: hamburgerSpan2_close .3s forwards cubic-bezier(.8, .5, .2, 1.4);
                    }

                    &:nth-child(3) {
                        top: 16px;
                        left: 0;
                        animation: hamburgerSpan3_close .3s forwards cubic-bezier(.8, .5, .2, 1.4);
                    }
                }

                &--open {

                    span {
                        background-color: $sega-red;

                        &:nth-child(2) {
                            animation: hamburgerSpan2 0.2s forwards cubic-bezier(.8, .5, .2, 1.4);
                        }

                        &:nth-child(3) {
                            animation: hamburgerSpan3 0.2s forwards cubic-bezier(.8, .5, .2, 1.4);
                            animation-delay: 0.1s;
                        }

                        &:nth-child(1) {
                            animation: hamburgerSpan1 0.2s forwards cubic-bezier(.8, .5, .2, 1.4);
                            animation-delay: 0.1s;
                            top: -3px;
                        }
                    }
                }
            }
        }
    }

    &_contact {
        display: none;
    }

    @media only screen and (max-width: 1500px) {
        &_container_box_menu {
            margin-left: 5%;
        }
    }

    @media only screen and (max-width: 1400px) {
        &_container_box_menu {
            margin-left: 20px;
        }
    }

    @media only screen and (max-width: 1280px) {
        &_container {
            padding: 10px 0 0 0;

            &_top {
                padding-top: 0;
                width: auto;
                justify-content: flex-end;
                margin: auto 15px 0 15px;
            }

            &_box {
                &_logo {
                    margin-right: 20px;
                    text-align: center;
                    width: 160px;
                }

                &_more {
                    margin-left: 0;
                }

                &_menu {
                    margin-left: auto;

                    &_list_item {
                        font-size: 14px;
                        margin-left: 0;

                        a {
                            padding: 15px 5px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        height: 65px;
        min-height: inherit;

        &_container {
            display: flex;
            height: 100%;
            padding: 0;

            &_top {
                margin: 0;

                &_contact {
                    align-items: center;
                    bottom: 0;
                    display: flex;
                    height: 80px;
                    justify-content: center;
                    flex-wrap: wrap;
                    left: 0;
                    opacity: 0;
                    pointer-events: none;
                    padding: 0 15px;
                    position: fixed;
                    width: 100%;
                    z-index: 99;

                    &:after {
                        background: linear-gradient(0deg, rgba(245,245,245,1) 50%, rgba(245,245,245,0) 100%);
                        content: '';
                        bottom: 0;
                        height: 90px;
                        left: 0;
                        position: absolute;
                        width: 100%;
                        z-index: 0;
                    }

                    span {
                        margin-left: 0;
                        position: relative;
                        z-index: 1;
                    }

                    &--active {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }

                &_list {
                    display: none;
                }
            }

            &_box {
                align-items: center;
                width: 100%;

                &_menu {
                    display: none;
                    opacity: 0;
                    transition: all 0.2s ease;

                    &--active {
                        animation: opacityLeft 0.2s;
                        background: $sega-grey;
                        display: block;
                        height: calc(100vh - 110px);
                        left: 0;
                        margin-left: 0;
                        opacity: 1;
                        padding: 30px 15px 120px 15px;
                        transition: all 0.2s ease;
                        top: 110px;
                        overflow: auto;
                        position: absolute;
                        width: 100%;
                    }

                    &_list {
                        display: block;
                        margin: auto;
                        width: 100%;

                        &.only-mobile {
                            display: block;
                        }

                        &_item {
                            background: transparent;
                            border-bottom: 1px solid $sega-blue;
                            border-radius: 0;
                            margin-bottom: 0;
                            padding: 0;
                            text-align: left;

                            &:last-child {
                                margin-right: 0;
                                margin-left: 0;
                            }

                            &:first-child {
                                margin-left: 0;
                            }

                            a {
                                cursor: pointer;
                                display: block;
                                font-size: 16px;
                                padding: 15px 25px;
                            }
                        }

                        &.only-mobile {
                            .sega__header_container_box_menu_list_item {
                                font-weight: 200;
                            }
                        }
                    }
                }

                &_more {
                    margin-left: auto;

                    &_list_item.search-icon {
                        display: none;
                    }
                }
            }

            &_hamburger {
                display: block;

                &_line {
                    margin: 8px auto 2px auto;

                    &--open {
                        span {
                            background-color: $sega-red;
                        }
                    }
                }

                &:hover {
                    color: $sega-red;
                }
            }
        }
    }

    @media only screen and (max-width: 560px) {
        &_container_box {
            &_logo {
                margin-right: 10px;
                width: 120px;
            }

            &_menu--active {
                height: calc(100vh - 100px);
                top: 100px;
            }
        }

        &_contact {
            background: $sega-blue;
            bottom: 0;
            color: $sega-white;
            display: block;
            left: 0;
            padding: 5px 0;
            position: fixed;
            text-align: center;
            width: 100%;
            z-index: 10;

            span.text {
                display: none;
            }

            a {
                color: $sega-white;
                display: inline-block;
                margin-left: 10px;
                padding: 5px 10px 5px 25px;
                position: relative;

                &:before {
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    content: '';
                    height: 21px;
                    left: 0;
                    position: absolute;
                    top: calc(50% - 12px);
                    width: 21px
                }

                &:last-of-type:before {
                    background-image: url('/assets/img/email-us.svg');
                }

                &:first-of-type:before {
                    background-image: url('/assets/img/call-us.svg');
                }
            }
        }
    }

    @media only screen and (max-width: 470px) {
        &_container {
            &_top_contact {
                span {
                    line-height: 2;
                    text-align: center;

                    .text {
                        display: none;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 420px) {
        &_contact {
            .separator {
                display: none;
            }

            a {
                margin: 0
            }
        }

        &_container_box {
            &_logo {
                width: 110px;
            }

            &_more_list {
                align-items: flex-start;

                &_item {
                    a,
                    button,
                    div {
                        margin-left: 10px;
                        width: 50px;
                    }

                    &.cart-quote-items a {
                        margin-left: 0;
                    }
                }
            }
        }

        &_hamburger {
            align-self: flex-start;
            margin-top: 3px;
            margin-left: 10px;
        }
    }

    @media only screen and (max-width: 360px) {
        &_container {
            &_box_more_list_item {
                font-size: 10px;

                a,
                button,
                div {
                    margin-left: 8px;
                    width: 42px;
                }
            }

            &_hamburger {
                font-size: 10px;
                margin-left: 8px;

                &_line {
                    margin: -3px auto 2px auto;
                }
            }
        }
    }
}
