@import "../../variables/colors";
@import "../../variables/keyframes";

.sega__prizeAndParts {
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15);
    background: $sega-white;

    &.no-bg {
        background: none;
        box-shadow: none
    }

    &_panel {
        display: flex;
        justify-content: space-around;
        margin: 40px auto 20px auto;
        max-width: 1360px;
        width: 100%;

        p {
            color: $sega-black;
            padding: 0 15px;
            max-width: 980px;
            margin: 25px 0;
            text-align: center;
            font-size: 18px;
        }

        &:last-child {
            margin-top: 20px;
        }

        &_search {
            display: flex;
            justify-content: center;
            max-width: 980px;
            padding: 20px;
            position: relative;
            width: 100%;

            input {
                padding: 10px 20px 10px 20px;
                width: 100%;
                border: 2px solid $sega-dark-grey;
            }

            button {
                margin-left: 850px;
                background: 0 0;
                border: none;
                cursor: pointer;
                height: 42px;
                position: absolute;
                right: 35px;
                top: 22px;
                transition: .2s ease-in-out;
                width: 35px;

                img {
                    position: relative;
                }
            }
        }
    }

    .sega__products_boxesMax_productWhite a {
        background-repeat: no-repeat;
        background-color: $sega-white;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 222px;
        transition: all .2s ease-in-out;

        .sega__products_boxesMax_productWhite_name {
            margin-top: 10px;
            width: 222px
        }

        .sega__productsTab_content_products_product_name {
            width: 222px;
            background-color: $sega-white;
            min-height: 50px;
            display: inline-flex;
            justify-content: center;

            span {
                border-top: 1px solid rgba(8,58,126,.5);
                font-weight: 600;
                font-size: 14px;
                margin: 0 auto;
                padding: 10px 5px;
                text-align: center;
                text-transform: uppercase;
                transition: all .2s ease-in-out;
                width: 80%;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .sega__prizeAndParts {
        &_panel {
            &_search {
                button {
                    margin-left: 80%;
                }
            }
        }
    }
}

@media only screen and (max-width: 690px) {
    .sega__prizeAndParts {
        &_panel {
            &_search {
                button {
                    margin-left: 60%;
                }
            }
        }
    }
}
