@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__searchBox {
    padding-bottom: 2rem;

    &_container {
        @include searchBar;

        &_box {
            margin-top: 5px;
            margin-bottom: 5px;
            width: 250px;
            border-right: 1px solid;
            border-left: 1px solid;
            border-color: rgba(0, 0, 0, 0.1);
            text-align: center;
            font-size: 20px;
            text-transform: uppercase;
            padding: 17px;
            margin-block: auto;

            &:nth-child(3) {
                padding: 14px;
            }

            &:last-child {
                border-right: none;
            }

            h3 {
                font-size: 22px;
                &:last-child {
                    margin-top: 0;
                }
            }
        }

        .borderNone {
            border-left: none !important;
        }
    }
    @media only screen and (max-width: 1150px) {
        display: none;
    }
}

.search-padding {
    padding-top: 8rem;

    .sega__searchBox_container {
        border-radius: 50px;
        justify-content: flex-start;
        margin: 0 auto;
        max-width: 1080px;
        overflow: hidden;
        padding-right: 30px;
        position: relative;

        &_box {
            text-align: left;
        }

        input {
            &::placeholder {
                text-align: left;
            }
        }
    }
}

@media only screen and (max-width: 1150px) {

    .search-padding {
        display: block;

        input {

            &::placeholder {
           font-size: 100%;
            }
        }

        button {
            margin-right: 30px;
        }
    }
}
@media only screen and (max-width: 980px) {

    .search-padding {
        padding-top: 8rem;
    }
}
