@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__product {

    &_container {
        justify-content: space-evenly;
        max-width: 980px;
        margin: 20px auto;
        display: flex;

        .not-found {
            padding: 0;
            margin-top: 37px;
            text-align: center;
        }

        &_boxLeft {

            &_galleryMin, &_galleryMax {

                &_slider {
                    position: relative;
                    overflow: hidden;
                    background-color: $sega-grey;

                    &_images {
                        margin: 0;
                        position: relative;
                        text-align: center;

                        h2 {
                            font-size: 35px;
                            text-transform: uppercase;
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 900;
                            display: none;
                        }

                        .item {
                            padding: 0;

                            .img-fill {
                                cursor: pointer;
                                text-align: center;
                                padding: 10px;
                                width: 100%;
                                height: 100%;
                                display: list-item;

                                img {
                                    width: 96%;
                                    display: inline-block;
                                    position: relative;
                                    max-height: 100%;
                                    background: $sega-white;
                                    max-width: 410px !important;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }

                        .next-arrow, .prev-arrow {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 47px;
                            height: 47px;
                            border-radius: 50%;
                            background: $sega-blue;
                            border: 0 none;
                            text-align: center;
                            color: #FFF;
                            z-index: 5;
                            opacity: 0;
                            outline: 0;
                            transition: 0.2s ease-in-out;

                            &:hover {
                                transition: 0.2s ease-in-out;
                                background: $sega-red;
                            }
                        }

                        .next-arrow {
                            right: 25px;

                            &:before {
                                margin-top: -9px;
                                margin-right: -2px;
                                display: block;
                                color: $sega-white;
                                content: '»';
                                font-family: 'futura-pt-bold', sans-serif;
                                font-weight: 900;
                                font-size: 35px;
                            }
                        }

                        .prev-arrow {
                            left: 25px;

                            &:before {
                                margin-top: 7px;
                                margin-right: 2px;
                                transform: rotate(180deg);
                                display: block;
                                color: $sega-white;
                                content: '»';
                                font-family: 'futura-pt-bold', sans-serif;
                                font-weight: 900;
                                font-size: 35px;
                            }
                        }

                        &:hover .next-arrow, &:hover .prev-arrow {
                            opacity: 1;
                        }

                        .slick-track {
                            height: 27rem;
                        }
                    }

                    &_thumbnails {
                        position: relative;
                        top: auto;
                        left: 0;
                        z-index: 4;
                        transition: 0.2s ease-in-out;
                        margin: 0;
                        padding: 13px 0 0 0;

                        &:before, &:after {
                            content: '';
                            display: block;
                            width: 100px;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            z-index: 10;
                            pointer-events: none;
                        }

                        .slider_thumbnail {
                            margin-bottom: 25px;

                            .item_thumbnail {
                                margin: 0 5px;
                            }
                        }

                        &:before {
                            left: 0;
                            background: linear-gradient(to right, rgba($sega-grey,1) 0%,rgba($sega-grey,0) 100%);
                        }

                        &:after {
                            right: 0;
                            background: linear-gradient(to right, rgba($sega-grey,0) 0%,rgba($sega-grey,1) 100%);
                        }

                        .item {

                            .img-fill {
                                height: 90px;
                                text-align: center;

                                img {
                                    transition: 0.2s ease-in-out;
                                    display: inline-block;
                                    position: relative;
                                    width: 90px;
                                    height: 90px!important;
                                    top: 50%;
                                    object-fit: contain;
                                    transform: translateY(-50%);
                                    box-shadow: none;
                                }
                            }

                            &:hover .img-fill img {
                                opacity: 1;
                            }

                            &.slick-center {
                                img {
                                    opacity: 1;
                                }
                            }

                        }
                        .next-arrow, .prev-arrow {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            background: transparent;
                            border: 0 none;
                            z-index: 15;
                            transition: 0.2s ease-in-out;

                            &:hover {

                                &:before {
                                    transition: 0.2s ease-in-out;
                                    color: rgba($sega-blue, 1);
                                }
                            }
                        }

                        .next-arrow {
                            right: 0;

                            &:before {
                                margin-top: -9px;
                                margin-right: -2px;
                                display: block;
                                color: rgba($sega-blue, 0.2);
                                content: '»';
                                font-weight: 100;
                                font-size: 35px;
                            }
                        }

                        .prev-arrow {
                            left: 0;

                            &:before {
                                margin-top: 7px;
                                margin-right: 2px;
                                transform: rotate(180deg);
                                display: block;
                                color: rgba($sega-blue, 0.2);
                                content: '»';
                                font-weight: 100;
                                font-size: 35px;
                            }
                        }

                    }

                    .next-arrow, .prev-arrow {
                        cursor: pointer;

                        svg {
                            width: 18px;
                            height: 18px;
                            position: relative;
                        }
                    }

                    .img-fill {
                        width: 100%;
                        display: block;
                        overflow: hidden;
                        position: relative;
                        text-align: center;

                        img {
                            border: 2px solid $sega-dark-grey;
                        }
                    }

                    .slick-slider {
                        margin: 0 -7.5px;
                        padding: 0!important;
                        margin-bottom: 40px;
                    }

                    .slick-slide {
                        outline: none;
                    }

                    .track-down {
                        height: 100px;
                    }
                }
            }

            &_galleryMax {
                max-width: 430px;

                &_slider {

                    &_images {

                        h2 {
                            font-size: 35px;
                            text-transform: uppercase;
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 900;
                            display: none;
                        }

                        .item {

                            .img-fill {

                                img {
                                    max-width: 430px;
                                    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
                                }
                            }
                        }

                        .slick-track {
                            height: 27rem;
                        }
                    }

                    &_thumbnails {

                        .item {

                            .img-fill {

                                img {
                                    max-width: 430px;
                                }
                            }
                        }
                    }
                }
            }

            &_galleryMin {
                max-width: 330px;

                &_slider {

                    &_images {

                        .item {

                            .img-fill {

                                img {
                                    max-width: 340px;
                                    box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
                                }
                            }
                        }

                        .slick-track {
                             height: 21rem;
                         }
                    }

                    &_thumbnails {

                        .item {

                            .img-fill {

                                img {
                                    margin: 0 15px;
                                    max-width: 340px;
                                }
                            }
                        }

                        .slick-track {
                            margin-left: -15px;
                        }
                    }
                }
            }

            &_video {
                width: 430px;
                text-align: center;

                h4 {
                    text-transform: uppercase;
                    color: $sega-blue;
                    padding-top: 15px;
                    border-top: 1px solid $sega-blue;
                    font-size: 22px;
                }

                iframe {
                    width: 100%;
                    height: 243px;
                    margin-top: 20px;
                    outline: none;
                    border: none;
                }
            }
        }

        &_boxRight {
            margin-left: 20px;

            &_head {

                h2 {
                    font-size: 35px;
                    margin: 0;
                    text-transform: uppercase;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 800;
                }

                h3 {
                    color: $sega-red;
                    text-transform: uppercase;
                    font-size: 20px;
                    width: 100%;
                    margin-top: 11px;
                }

                &_description {
                    display: flex;

                    &_text {

                        p {
                            color: $sega-black;
                            display: block;
                            margin: 10px 0;

                            img {
                                margin-left: 10px;
                                width: auto;
                                height: 100%!important
                            }
                        }

                        #enquiry-image {
                            float: right;
                            height: 124px;
                            margin-left: 30px;
                            margin-top: 10px;
                            margin-bottom: 10px;
                            width: 124px; 
                        }

                        .add-to-quote {
                            display: block;
                            height: 100%;
                            position: relative;
                            width: 100%;

                            .add-to-quote-img {
                                display: block;
                                height: 100%!important;
                                object-fit: contain;
                                width: 100%;
                            }
                        }

                        &_info {
                            margin-top: 35px;

                            h3 {
                                font-size: 20px;
                                text-align: center;
                                font-family: 'futura-pt-bold', sans-serif;
                                font-weight: 800;
                                color: $sega-blue;
                            }

                            ul {
                                margin-top: 19px;
                                color: $sega-black;
                                list-style: disc;

                                li {
                                    margin-left: 15px;
                                    font-size: 16px;
                                    margin-bottom: 10px;
                                }
                            }

                            p {
                                margin-top: 15px;
                            }
                        }
                    }

                    &_basket {
                        min-width: 250px;
                        margin-left: 26px;
                        height: 100%;

                        h2 {
                            font-size: 37px;
                        }

                        h3 {
                            text-transform: uppercase;
                            color: $sega-blue;
                            font-size: 17px;
                            width: 100%;
                            margin-top: 0;
                            margin-left: 35px;
                            font-weight: 800;
                            font-family: 'futura-pt-bold', sans-serif;
                        }

                        form {

                            label {
                                display: flex;
                                margin-left: 35px;

                                span {
                                    font-size: 18px;
                                    margin-right: 15px;
                                    margin-top: 6px;
                                }

                                input {
                                    border: none;
                                    border-radius: 0;
                                    width: 48px;
                                    padding-left: 17px;
                                    height: 32px;
                                    box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.35);
                                }
                            }

                            button {
                                margin-top: 20px;
                                font-size: 17px;
                            }
                        }

                        .heart {
                            background: transparent;
                            border: none;
                            cursor: pointer;
                            outline: none;
                            width: 100%;
                            text-align: center;
                            font-weight: 800;
                            font-family: 'futura-pt-bold', sans-serif;
                            text-transform: uppercase;
                            margin-top: 20px;
                            color: $sega-blue;
                            transition: 0.2s ease-in-out;

                            svg {
                                width: 18px;
                                vertical-align: bottom;
                                stroke: $sega-blue;
                                fill: none;
                                stroke-miterlimit: 10;
                                stroke-width: 3px;
                                transition: 0.2s ease-in-out;
                            }

                            &:hover {
                                transition: 0.2s ease-in-out;
                                color: rgba($sega-red, 0.8);

                                svg {
                                    transition: 0.2s ease-in-out;
                                    stroke: $sega-red;
                                }
                            }

                            &:active {
                                transition: 0.2s ease-in-out;
                                transform: scale(0.8);
                            }
                        }
                    }
                }
            }

            &_data {
                display: flex;
                justify-content: space-between;

                h3 {
                    text-transform: uppercase;
                    font-size: 20px;
                    margin: 20px 0;
                    text-align: center;
                    font-weight: 800;
                    font-family: 'futura-pt-bold', sans-serif;
                    color: $sega-blue;
                }

                &_features {

                    ul {
                        margin-top: 19px;
                        color: $sega-black;
                        list-style: disc;

                        li {
                            margin-left: 15px;
                            font-size: 16px;
                            margin-bottom: 10px;
                        }
                    }

                    p {
                        margin-top: 15px;
                    }
                }

                &_type {
                    min-width: 140px;
                    margin-left: 30px;

                    ul {

                        li {
                            margin-top: 20px;
                            display: block;
                            text-align: center;
                            color: $sega-black;

                            img {
                                width: 38px;
                                height: auto!important;
                            }
                        }
                    }
                }
            }

            &_quality {

                ul {
                    display: flex;
                    justify-content: space-around;
                    margin: 30px 0;

                    li {
                        text-align: center;
                        max-width: 120px;

                        h3 {
                            text-transform: uppercase;
                            margin-top: 15px;
                            font-size: 20px;
                        }

                        p {
                            font-size: 15px;
                        }
                    }

                    &:empty {
                        & + .border-line {
                            display: none;
                        }
                    }
                }
            }

            &_technicalData {
                margin-top: -20px;

                .wrapper {
                    min-width: 600px;
                    max-width: 900px;
                    margin: 0 auto;
                }

                .tabs {
                    border-top: 1px solid $sega-blue;
                    display: table;
                    table-layout: fixed;
                    width: 100%;
                    -webkit-transform: translateY(5px);
                    transform: translateY(5px);

                    h3 {
                        color: $sega-blue;
                        text-transform: uppercase;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;

                        &:after {
                            display: inline-flex;
                            transform: rotate(0deg);
                            margin-left: 15px;
                            color: $sega-blue;
                            content: '»';
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 900;
                            font-size: 24px;
                            transition: 0.2s ease-in-out;
                        }
                    }

                    p {
                        transition: 0.2s ease-in-out;
                        color: $sega-red;
                        display: none;
                    }

                    li {
                        border-bottom: 2px solid $sega-blue;

                        &:first-child {
                            border-left: none;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }

                    .active {
                        transition: 0.2s ease-in-out;
                        border-radius: 30px 30px 0 0;
                        border-left: 2px solid $sega-blue;
                        border-right: 2px solid $sega-blue;
                        border-bottom: 2px solid $sega-grey;

                        h3 {
                            color: $sega-red;

                            &:after {
                                display: inline-flex;
                                color: $sega-red;
                                vertical-align: text-bottom;
                                transform: rotate(90deg);
                            }
                        }

                        p {
                            transition: 0.2s ease-in-out;
                            display: block;
                            color: $sega-red;
                            text-transform: uppercase;
                        }
                    }

                    li {
                        transition-duration: 0.2s;
                        display: table-cell;
                        list-style: none;
                        text-align: center;
                        padding: 20px 20px 25px 20px;
                        position: relative;
                        overflow: hidden;
                        cursor: pointer;
                        color: white;

                        &:hover{

                            &:before{
                                -webkit-transform: translateY(70%);
                                transform: translateY(70%);
                            }
                        }

                        &.active{
                            color: rgb(80, 85, 90);

                            &:before{
                                transition-duration: 0.2s;
                                background-color: transparent;
                                -webkit-transform: translateY(0);
                                transform: translateY(0);
                            }
                        }
                    }

                    &.one-item {
                        border-top: none;
                        
                        li {
                            border-top: 1px solid $sega-blue;
                            border-right: 2px solid $sega-blue;
                            border-radius: 0 30px 0 0;
                        }
                    }
                }

                .tab__content{
                    background-color: $sega-grey;
                    position: relative;
                    width: 100%;
                    border-radius: 5px;
                    margin-top: 4px;
                    height: auto;

                    li {
                        display: none;
                        min-height: 410px;
                        width: 100%;

                        .content__wrapper {
                            text-align: center;
                            border-radius: 5px;
                            width: 100%;
                            padding: 15px 20px;
                            background-color: transparent;

                            &.plr-0 {
                                padding: 15px 0;
                            }

                            &_box {

                                .game-dimensions-table {
                                    margin: 15px 0 30px 0;

                                    &__inner {
                                        align-items: flex-start;
                                        display: flex;
                                        flex-wrap: wrap;
                                        margin-bottom: 15px;
                                    }

                                    &__title {
                                        color: $sega-blue;
                                        font-family: 'futura-pt-bold', sans-serif;
                                        font-weight: 700;
                                        text-align: left;
                                        text-transform: uppercase;
                                        width: 170px;

                                        &-small {
                                            font-weight: 400;
                                            margin-top: 8px;
                                        }
                                    }

                                    &__content {
                                        align-items: flex-start;
                                        display: flex;
                                        justify-content: flex-start;
                                        width: calc(100% - 170px);

                                        &-col {
                                            align-items: center;
                                            border-right: 1px solid;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            height: 100%;
                                            width: 25%;

                                            div {
                                                color: $sega-black;
                                                font-size: 14px;
                                                padding: 8px 5px;

                                                &:first-of-type {
                                                    font-family: 'futura-pt-bold', sans-serif;
                                                    font-weight: 700;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ul {

                        .active {
                            border: none;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 2rem;
                                background-color: $sega-red;
                            }
                        }
                    }
                }

                .content__wrapper{

                    h2{
                        width: 100%;
                        text-align: center;
                        padding-bottom: 20px;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    &_sliders {
        display: flex;
        max-width: 980px;
        width: 100%;
        margin: auto;
        padding-bottom: 40px;

        h3 {
            margin: 25px 0;
            text-transform: uppercase;
        }

        &_slider {
            max-width: 490px;
            width: 100%;
            padding: 0 40px;
            margin: 0 !important;
            @include segaSlider;
        }

        .slick-prev {
            top: 45%;
            &:before {
                font-size: 30px;
            }
        }

        .slick-next {
            top: 40%;
            &:before {
                font-size: 30px;
            }
        }
    }

    .slider {

        .slick-track {
            height: 95px;

            .slick-slide {
                img {
                    box-shadow: none;
                    border: 1px solid $sega-dark-grey;
                    display: block;
                    margin: 0 auto;
                    width: 90px;
                }
            }
        }
    }


    @media only screen and (max-width: 1020px) {
        padding: 0 20px;

        &_container {

            &_boxLeft {

                &_galleryMin, &_galleryMax {

                    &_slider {

                        &_images {

                            .item {

                                .img-fill {

                                    img {
                                        height: 100%!important;
                                        width: 100%;
                                        max-width: none !important;
                                    }
                                }
                            }

                            .next-arrow, .prev-arrow {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 47px;
                                height: 47px;
                                border-radius: 50%;
                                background: $sega-blue;
                                border: 0 none;
                                text-align: center;
                                color: #FFF;
                                z-index: 5;
                                opacity: 0;
                                outline: 0;
                                transition: 0.2s ease-in-out;

                                &:hover {
                                    transition: 0.2s ease-in-out;
                                    background: $sega-red;
                                }
                            }

                            .next-arrow {
                                right: 25px;

                                &:before {
                                    margin-top: -9px;
                                    margin-right: -2px;
                                    display: block;
                                    color: $sega-white;
                                    content: '»';
                                    font-family: 'futura-pt-bold', sans-serif;
                                    font-weight: 900;
                                    font-size: 35px;
                                }
                            }

                            .prev-arrow {
                                left: 25px;

                                &:before {
                                    margin-top: 7px;
                                    margin-right: 2px;
                                    transform: rotate(180deg);
                                    display: block;
                                    color: $sega-white;
                                    content: '»';
                                    font-family: 'futura-pt-bold', sans-serif;
                                    font-weight: 900;
                                    font-size: 35px;
                                }
                            }

                            &:hover .next-arrow, &:hover .prev-arrow {
                                opacity: 1;
                            }

                            .slick-track {
                                height: 27rem;
                            }
                        }

                        &_thumbnails {
                            position: relative;
                            top: auto;
                            left: 0;
                            z-index: 4;
                            transition: 0.2s ease-in-out;
                            margin: 0;
                            padding: 13px 0 0 0;

                            &:before, &:after {
                                content: '';
                                display: block;
                                width: 100px;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                z-index: 10;
                                pointer-events: none;
                            }

                            .slider_thumbnail {
                                margin-bottom: 25px;

                                .item_thumbnail {
                                    margin: 0 5px;
                                }
                            }

                            &:before {
                                left: 0;
                                background: linear-gradient(to right, rgba($sega-grey,1) 0%,rgba($sega-grey,0) 100%);
                            }

                            &:after {
                                right: 0;
                                background: linear-gradient(to right, rgba($sega-grey,0) 0%,rgba($sega-grey,1) 100%);
                            }

                            .item {

                                .img-fill {
                                    height: 90px;
                                    text-align: center;

                                    img {
                                        transition: 0.2s ease-in-out;
                                        display: inline-block;
                                        position: relative;
                                        width: 90px;
                                        height: 90px!important;
                                        top: 50%;
                                        object-fit: contain;
                                        transform: translateY(-50%);
                                        box-shadow: none;
                                    }
                                }

                                &:hover .img-fill img {
                                    opacity: 1;
                                }

                                &.slick-center {
                                    img {
                                        opacity: 1;
                                    }
                                }

                            }
                            .next-arrow, .prev-arrow {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                background: transparent;
                                border: 0 none;
                                z-index: 15;
                                transition: 0.2s ease-in-out;

                                &:hover {

                                    &:before {
                                        transition: 0.2s ease-in-out;
                                        color: rgba($sega-blue, 1);
                                    }
                                }
                            }

                            .next-arrow {
                                right: 0;

                                &:before {
                                    margin-top: -9px;
                                    margin-right: -2px;
                                    display: block;
                                    color: rgba($sega-blue, 0.2);
                                    content: '»';
                                    font-weight: 100;
                                    font-size: 35px;
                                }
                            }

                            .prev-arrow {
                                left: 0;

                                &:before {
                                    margin-top: 7px;
                                    margin-right: 2px;
                                    transform: rotate(180deg);
                                    display: block;
                                    color: rgba($sega-blue, 0.2);
                                    content: '»';
                                    font-weight: 100;
                                    font-size: 35px;
                                }
                            }

                        }

                        .next-arrow, .prev-arrow {
                            cursor: pointer;

                            svg {
                                width: 18px;
                                height: 18px;
                                position: relative;
                            }
                        }

                        .img-fill {
                            width: 100%;
                            display: block;
                            overflow: hidden;
                            position: relative;
                            text-align: center;

                            img {
                                border: 2px solid $sega-dark-grey;
                            }
                        }

                        .slick-slider {
                            margin: 0 -7.5px;
                            padding: 0!important;
                            margin-bottom: 40px;
                        }

                        .slick-slide {
                            outline: none;
                        }

                        .track-down {
                            height: 100px;
                        }
                    }
                }
            }
        }

        &_sliders {
            display: block;

            &_slider {
                max-width: none;
                padding: 0;

                .slick-prev {
                    left: 0;
                }

                .slick-next {
                    right: 0;
                }
            }

        }
    }
    @media only screen and (max-width: 940px) {

        &_container {
            display: block;

            &_boxLeft {

                &_galleryMin, &_galleryMax {
                    display: inline;

                    &_slider {
                        &_images {
                            h2 {
                                display: block;
                            }

                            .slick-track {
                                height: 27rem;
                            }

                            .prev-arrow,
                            .next-arrow {
                                bottom: 10.5rem;
                                top: inherit
                            }
                        }
                    }
                }

                &_galleryMax {

                    &_slider {

                        &_images {
                            h2 {
                                display: block;
                            }
                        }
                    }
                }

                &_galleryMin {

                    &_slider {

                        &_images {

                            .item {

                                .img-fill {

                                    img {
                                        max-width: 400px;
                                    }
                                }
                            }
                        }
                    }
                }

                &_video {
                    width: 100%;

                    iframe {

                        height: 350px;
                    }
                }
            }

            &_boxRight {
                margin-left: 0;
                margin-top: 25px;

                h2, h3 {
                    text-align: center;
                }

                &_head {

                    &_description {
                        display: block;

                        &_text {
                            display: grid;
                            text-align: center;

                            &_info {

                                p {
                                    justify-content: center;
                                }

                                ul {
                                    text-align: center;
                                    list-style: none;
                                    justify-content: center;
                                }
                            }
                        }

                        &_basket {
                            text-align: center;
                            margin-left: 0;

                            h3 {
                            margin-left: 0;
                            }

                            form {

                                label {
                                    justify-content: center;
                                    margin-left: auto;
                                }
                            }
                        }

                        #enquiry-image {
                            float: inherit!important;
                            margin: 10px auto 15px auto!important;
                        }
                    }
                    &_technicalData {

                        .tab__content {

                            min-width: max-content;
                            overflow-x: auto;

                            li {

                                .content__wrapper {
                                    min-width: 500px;

                                    &_box {

                                        table {
                                            margin-bottom: 20px;
                                        }

                                        td, th {

                                            h4 {
                                                color: $sega-red;
                                            }

                                            &:after {
                                                display: none;
                                            }
                                        }

                                        th {

                                            &:after {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }

                            ul {

                                .active {
                                    border: none;

                                    &:after {
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        width: 100%;
                                        height: 2rem;
                                        background-color: $sega-red;
                                    }
                                }
                            }
                        }
                    }
                }

                &_technicalData {
                    .tab__content {
                        min-width: auto;
                        overflow: auto;

                        ul {
                            .active {
                                border: none;

                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    width: 100%;
                                    height: 2rem;
                                    background-color: $sega-red;
                                }
                            }
                        }
                    }
                }

                &_data {
                    &_features,
                    &_type {
                        width: 50%;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 550px) {

        &_container {

            &_boxRight {

                &_technicalData {

                    .tabs {
                        display: grid;
                        border-bottom: 1px solid #083a7e !important;

                        li {
                            border-bottom: 1px solid #083a7e !important;
                            min-height: 92px;
                        }
                    }

                    .tab__content li .content__wrapper_box .game-dimensions-table {
                        &__title {
                            font-size: 15px;
                            width: 150px;
                        }

                        &__content {
                            width: calc(100% - 150px)
                        }
                    }

                    .active {
                        border-right: none !important;
                        border-left: none !important;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 475px) {

        &_container {

            &_boxLeft {

                &_galleryMin, &_galleryMax {

                    &_slider {

                        &_images {

                            .item {

                                .img-fill {

                                    img {
                                        max-width: none;
                                        width: inherit;
                                    }
                                }
                            }
                        }
                        &_thumbnails {
                            padding: 0;
                        }
                    }
                }

                &_video {

                    iframe {

                        height: 240px;
                    }
                }
            }

            &_boxRight {

               &_head {

                   &_description {

                       &_text {

                           p {
                               display: block;

                               img {
                                   display: block;
                                   margin: 20px auto;
                               }
                           }
                       }
                   }
               }

               &_data {
                   display: block;
                   text-align: center;

                    &_features,
                    &_type {
                        width: 100%;
                    }

                    &_features {
                       ul {
                           list-style: none;

                           li {
                               margin-left: 0;
                           }
                       }
                   }

                    &_type {
                        margin-left: 0;

                        ul {
                            align-items: center;
                            display: flex;
                            justify-content: center;

                            li {
                                margin: 10px;
                            }
                        }
                    }

                    &_technicalData {
                        text-align: center;
                    }
                }

                &_quality {
                    ul {
                        flex-wrap: wrap;

                        li {
                            margin: 10px;
                            width: calc(50% - 20px);
                            padding: 10px;
                            max-width: inherit;
                        }
                    }
                }

                &_technicalData {
                    .tab__content li {
                        min-height: 580px;
                        
                        .content__wrapper_box .game-dimensions-table {
                            &__title {
                                margin-bottom: 15px;
                                max-width: inherit;
                                text-align: center;
                                width: 100%;
                            }

                            &__content {
                                justify-content: center;
                                width: 100%;

                                &-col {
                                    &:last-of-type,
                                    &.last-type {
                                        border-right: none;
                                    }

                                    &:empty {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &_sliders {
            display: block;

            &_slider {
                max-width: none;
            }

        }
    }
}

.sega__product_container_boxLeft_galleryMax_slider_thumbnails .slick-list {
    padding: 0 45px !important;
}

.sega__product_container_boxLeft_galleryMin_slider_thumbnails .slick-list {
    padding: 0 40px !important;
}
