@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__searchResult {
    width: 100%;

    &_container {
        margin: auto;
        width: 1000px;
        display: flex;
        justify-content: center;

        .title-rwd {
            display: none;
            background: linear-gradient(274deg, rgba(245,245,245,1) 0%, rgba(219,218,218,0.6) 49%, rgba(245,245,245,1) 100%);
            text-align: center;
            font-size: 25px;
            text-transform: uppercase;
            margin-bottom: 20px;
            padding: 20px;
        }

        &_box {
            width: 250px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-right: 1px solid;
            border-left: 1px solid;
            border-color: rgba(0, 0, 0, 0.1);

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            .sega__productsBasket_content_products_product {
                filter: none;
                margin: 0 auto 20px auto !important;
            }

            &_item {
                margin: 10px auto 20px auto;

                &_name {
                    span {
                        font-size: 13px;
                    }
                }
            }

            .sega__productsTab_content_products_product,
            .sega__productsBasket_content_products_product {
                width: 222px;
                padding: 0;
                display: grid;
                background-repeat: no-repeat;
                background-color: $sega-white;
                margin: 10px auto;

                img {
                    object-fit: contain;
                    width: 222px;
                    height: 100% !important;
                }

                &_name {
                    width: 222px;
                    background-color: $sega-white;
                    min-height: 50px;
                    margin-top: -4px;
                    display: inline-flex;
                    justify-content: center;

                    span {
                        text-transform: uppercase;
                        border-top: 1px solid rgba($sega-blue, 0.5);
                        padding: 10px 0;
                        color: $sega-blue;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                        text-align: center;
                        font-size: 12px;
                        width: 80%;
                        margin-top: auto;
                        margin-bottom: 0;
                    }
                }

                &_basket {
                    margin: -10px auto 0 auto;
                    padding-top: 2px;
                    width: 222px;

                    p {
                        color: $sega-blue;
                        font-size: 14px;
                        margin-bottom: 4px;
                    }

                    h3 {
                        text-transform: uppercase;
                        font-size: 14px;
                        margin-top: 0;
                    }

                    &_form {

                        h2 {
                            margin-block: auto;
                            font-size: 15px;
                        }
                    }

                    .basket-add {

                        label {
                            margin-block: auto;

                            span {
                                font-size: 14px;
                                margin-left: 6px;
                            }

                            input {
                                height: 18px;
                            }
                        }

                        button {
                            margin-block: auto;
                            transform: scale(0.8);
                            margin-left: -2px;

                            .add-button {
                                font-size: 16px;
                            }

                            img {
                                width: 17px;
                            }
                        }
                    }
                }
            }

            .product-info-white {
                height: 158px;
            }

            &_seeAll {
                text-align: center;
                width: 220px;
                margin: 40px auto 20px auto;

                h3 {
                    text-transform: uppercase;
                    font-size: 18px;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 900;
                }

                button {
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 600;
                    cursor: pointer;
                    transition: 0.2s ease-in-out;
                    padding: 0px 18px 0 40px;
                    background: white;
                    border: 1px solid $sega-red;
                    width: 100%;
                    margin-top: 15px;
                    justify-content: space-around;
                    color: $sega-red;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;

                    &:after {
                        content: '»';
                        font-size: 44px;
                        margin-top: -8px;
                        align-self: flex-end;
                    }

                    p {
                        margin-block: auto;
                    }

                    &:hover {
                        border: 1px solid $sega-blue;
                        color: $sega-blue;

                        p {
                            transition: 0.2s ease-in-out;
                            color: rgba($sega-blue, 0.8);

                            &:after {
                                transition: 0.2s ease-in-out;
                                color: rgba($sega-blue, 0.8);
                            }
                        }

                    }
                }
            }

        }
    }
    @media only screen and (max-width: 1150px) {

        &_container {
           display: block;
            width: auto;
            padding: 0 10px;

            .title-rwd {
                display: block;
            }

            &_box {
                display: flex;
                flex-wrap: wrap;
                max-width: 1150px;
                width: 100%;
                border-right: none;
                border-left: none;
                justify-content: center;
                border-bottom: 1px solid $sega-blue;

                &_item {
                    width: 242px;
                }

                &_seeAll {
                    width: 242px;
                    justify-content: center;
                    margin: auto 24px;

                    h3 {
                        margin-top: 20px;
                    }
                }

                .sega__productsBasket_content_products_product {

                    &_basket {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 460px) {

        .sega__searchResult_container_box_item .sega__productsTab_content_products_product {
            margin: 10px 10px 10px 12px;
        }
    }
    .borderNone {
        border-left: none !important;
    }
}
