@import "../variables/colors";
@import "../variables/keyframes";


.searching-ellipsis {
    display: none;
    height: 80px;
    left: calc(50% - 40px);
    margin: auto;
    position: absolute;
    top: calc(50% - 40px);
    transform: scale(1.4);
    width: 80px;
    z-index: 1;

    div {
        position: absolute;
        top: 33px;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $sega-blue;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 18px;
            animation: lds-ellipsis1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 18px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 42px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 66px;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }
}


