.sega-account {
  padding: 90px 0;

  &__inner {
    align-items: flex-start;
    display: flex;
    max-width: 975px;
    margin: 0 auto;
    padding: 0 15px;
  }

  &__menu {
    flex-shrink: 0;
    margin: 10px 20px 0 0;
    text-transform: uppercase;
    width: 220px;

    &-title {
      font-size: 20px;
      text-transform: uppercase;
    }

    &-list {
      margin-top: 12px;
      
      li {
        border-bottom: 1px solid $sega-blue;
        
        &:first-of-type {
          border-top: 1px solid $sega-blue;
        }
        
        a {
          align-items: center;
          display: flex;
          height: 100%;
          padding: 8px 18px 8px 0;
          position: relative;
          text-transform: uppercase;

          &:after {
            content: '»';
            color: $sega-blue;
            display: block;
            font-size: 36px;
            font-weight: 100;
            line-height: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: calc(50% - 4px);
            transition: all .15s ease-in-out;
          }

          &:active,
          &:focus,
          &:hover {
            color: $sega-red;

            &:after {
              color: $sega-red;
            }
          }
        }
      }
    }
  }

  &__content {
    padding-left: 20px;
  }

  &__content-menu {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;

    &.col-4 {
      padding: 0;

      .sega-account__content-menu-item {
        flex-direction: column;
        padding: 35px 40px;
        text-align: center;
        width: calc(25% - 20px);

        &:after {
          display: none;
        }

        .login-required {
          color: $sega-red;
          font-weight: 400;
          font-size: 13px;
        }

        svg {
          margin: 0 auto 20px auto;
        }
      }
    }
  }

  &__content-menu-item {
    align-items: center;
    background: $sega-white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba($sega-black, 0.1);
    display: flex;
    font-size: 20px;
    font-weight: 700;
    justify-content: flex-start;
    margin: 10px;
    padding: 40px;
    position: relative;
    text-transform: uppercase;
    transition: all .25s ease-in-out;
    width: calc(50% - 20px);
    will-change: transform;

    &:after {
      bottom: 25px;
      content: '»';
      color: $sega-blue;
      display: block;
      font-size: 42px;
      font-weight: 100;
      line-height: 0;
      pointer-events: none;
      position: absolute;
      right: 12px;
      transition: all .25s ease-in-out;
    }

    svg {
      display: block;
      flex-shrink: 0;
      height: 80px;
      margin-right: 40px;
      width: 80px;
    }

    &:active,
    &:focus,
    &:hover {
      box-shadow: 0 0 10px rgba($sega-black, 0.12);
      transform: scale(0.97);

      &:after {
        color: $sega-red;
      }
    }
  }
}

section.new-ca {
  max-width: 100%;

  .form-subtitle {
    font-size: 44px;
    margin: 0 auto 20px auto;
    text-transform: uppercase;
  }

  .add-person-button,
  .add-address-button,
  .stored_person_button {
    background: $sega-white;
    border: 1px solid $sega-dark-grey;
    border-radius: 4px;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.15);
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 7px 12px;
    text-transform: uppercase;
  }

  a.remove-person {
    margin-left: 10px;
    font-size: 1.2rem;
    color: $sega-white;
  }

  .stored_person_row:hover > a {
    color: #0b0a38;
  }

  .input__button_red {
    button {
      transition: all .25s ease-in-out;
    }

    #save-person-button {
      &:hover {
        background-color: $sega-blue;
        border: 1px solid $sega-blue;
      }
    }

    #cancel-person-button {
      background: $sega-white;
      color: $sega-red;
  
      &:hover {
        background-color: $sega-blue;
        border: 1px solid $sega-blue;
        color: $sega-white
      }
    }

    &:hover {
      button {
        opacity: 1;
        background-color: $sega-red;
        border: 1px solid $sega-red;
        transition: all .25s ease-in-out;
      }
    }
  }
}

.open-remove-person-popup {
  display: inline-block;
  padding: 8px 15px;

  &:hover {
    color: $sega-red;
  }
}

.remove-person-popup {
  background-color: rgba(3,27,34,.16);
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &.is-hidden {
    display: none;
  }

  .remove-person-popup__inner {
    align-items: center;
    background-color: $sega-grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 80%;
    left: 50%;
    max-height: 350px;
    max-width: 610px;
    padding: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 80%;

    p {
      font-size: 22px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }

    .remove-person,
    .close-remove-person-popup {
      border-radius: 20px;
      cursor: pointer;
      font-family: 'futura-pt-bold', sans-serif;
      font-weight: 600;
      font-size: 16px;
      margin: 20px;
      max-width: 200px;
      outline: none;
      opacity: 1;
      padding: 10px 12px;
      transition: 0.2s ease-in-out;
      text-transform: uppercase;
      text-align: center;
      width: calc(50% - 40px);
    }

    .remove-person {
      background-color: $sega-blue;
      border: 1px solid $sega-blue;
      color: $sega-white;

      &:hover {
        background-color: transparent;
        color: $sega-blue;
      }
    }

    .close-remove-person-popup {
      background-color: $sega-red;
      border: 1px solid $sega-red;
      color: $sega-white;

      &:hover {
        background-color: transparent;
        color: $sega-red;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .sega-account__menu {
    width: 200px;
  }
  
  .sega-account__content-menu {
    padding-left: 10px;

    &-item svg {
      height: 60px;
      margin-right: 20px;
      width: 60px;
    }
  }

  .sega-account__content-menu.col-4 .sega-account__content-menu-item {
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 840px) {
  .sega-account {
    padding: 60px 0;

    &__menu {
      margin: 10px 15px 0 0;
      width: 180px;

      &-title {
        font-size: 18px;
      }

      &-list {
        font-size: 14px;
      }
    }

    &__content-menu-item {
      font-size: 16px;
      padding: 20px 30px;
  
      svg {
        height: 45px;
        margin-right: 15px;
        width: 45px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .sega-account {
    padding: 30px 0 60px 0;

    &__inner {
      flex-wrap: wrap;
    }

    &__menu {
      border-radius: 20px;
      margin: 0 0 20px 0;
      width: 100%;

      &-title {
        display: none;
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;

        li {
          width: 50%;
        }

        li:nth-of-type(odd) {
          padding-right: 10px;
          border-right: 1px solid;
        }

        li:nth-of-type(even) {
          padding-left: 10px;
        }

        li:nth-of-type(2) {
          border-top: 1px solid $sega-blue
        }
      }
    }

    &__content-menu {
      padding: 0;

      &-item {
        font-size: 16px;
        padding: 20px 30px;
    
        svg {
          height: 45px;
          margin-right: 15px;
          width: 45px;
        }
      }
    }

    &__content-inner {
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: 560px) {
  .sega-account__content-menu-item {
    margin: 5px 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 380px) {
  .sega-account__menu-list li {
    width: 100%;

    &:nth-of-type(odd) {
      padding-right: 0;
      border-right: none;
    }

    &:nth-of-type(even) {
      padding-left: 0;
    }

    &:nth-of-type(2) {
      border-top: none
    }
  }
}