@import "../../variables/colors";
@import "../../variables/keyframes";

.sega__welcome {
    padding-top: 93px;

    &_container {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;

        &_img {
            width: 100%;
            height: auto;
            padding-bottom: 30%;;
            position: relative;

            video {
                height: 100%;
                left: 50%;
                object-fit: cover;
                position: absolute;
                pointer-events: none;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;

                &::-webkit-media-controls {
                    display: none;
                }
            }

            &_bg {
                width: 100%;
                height: 700px;
                background-size: 200% 200%;
                animation: slider_gradient 15s ease infinite;
            }

            .sega_welcome_video {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100%;
                left: 0;
                position: absolute;
                pointer-events: none;
                top: 0;
                width: 100%;

                &.is-hidden {
                    display: none;
                }

                .media-controls-container,
                .media-controls-container * {
                    display: none;
                }
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        padding-top: 110px;

        &_container {
            &_img {
                &_forAll {
                    margin-top: -21rem;
                }

                &_sideTitle {
                    &_box {
                        margin-top: -152px;

                        h1 {
                            margin-top: -170px;
                            font-size: smaller;
                        }
                    }
                }

                &_bg {
                    height: 570px;
                }
            }
        }
    }

    @media only screen and (max-width: 780px) {
        &_container {
            &_img {
                background-attachment: fixed;

                &_sideTitle {
                    font-size: 55px;
                }

                &_forAll {
                    display: grid;
                    margin-top: -25rem;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        &_container {
            &_img {
                &_forAll {
                    margin-top: -16rem;

                    img {

                        &:first-child {
                            width: 60%;
                            margin: auto;
                        }

                        &:last-child {
                            width: 60%;
                            margin: auto;
                        }
                    }
                }

                &_bg {
                    height: 400px;
                }
            }
        }
    }

    @media only screen and (max-width: 560px) {
        padding-top: 100px;
    }

    @media only screen and (max-width: 400px) {
        &_container {
            &_img {
                &_forAll {
                    margin-top: -14rem;
                }

                &_bg {
                    height: 350px;
                }
            }
        }
    }
}
