@import "../../variables/colors";
@import "../../variables/keyframes";

.sega__welcomeSide {

    &_container {
        overflow: hidden;
        padding-top: 93px;

        &_img {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            display: grid;
            min-height: 230px;

            &_box {
                display: grid;
                min-height: 230px;

                h1 {
                    margin: auto;
                    text-transform: uppercase;
                    font-size: 76px;
                    text-align: center;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 900;
                    color: $sega-white;
                    filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.8));
                }

                &_flex {
                    display: flex;
                    width: 800px;
                    margin: -20px auto auto auto;

                    img {
                        position: relative;
                        max-width: 300px;
                    }

                    h1 {
                        text-align: left;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        &_container {
            padding-top: 110px;

            &_img {

                &_box {
                    h1 {
                        font-size: 60px;
                    }

                    &_flex {
                        width: auto;

                        img {
                            max-width: 255px;
                        }

                        h1 {
                            font-size: 60px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 760px) {
        &_container {
            &_img {

                &_box {

                    &_flex {
                        display: block;

                        img {
                            display: block;
                            margin: auto;
                            max-width: 220px;
                        }

                        h1 {
                            margin-top: -55px;
                            font-size: 42px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 700px) {

        &_container {

            &_img {

                &_box {

                    h1 {
                        font-size: 30px;
                    }

                    &_flex {
                        margin: -10px auto auto;

                        h1 {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 560px) {
        &_container {
            padding-top: 100px;

            &_img {
                &_box {
                    h1 {
                        font-size: 35px;
                    }

                    &_flex {
                        h1 {
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}
