.sega__image-double {
  margin: 0 auto;
  max-width: 100%;

  &__container {
    align-items: center;
    display: flex;
    margin: 0 auto;
    max-width: 1360px;
    padding: 30px 0;
  }
  
  &__image {
    width: 50%;

    &:nth-of-type(even) {
      padding-left: 20px
    }

    &:nth-of-type(odd) {
      padding-right: 20px
    }

    img {
      display: block;
      height: auto!important;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}