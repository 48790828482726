@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__basketScroll {
    animation: fromDown 1s;
    background: $sega-white;
    display: none;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    z-index: 10;
    width: 100%;

    &--active {
        animation: fromTop 0.25s;
        display: none; //hidden fo client request #862k2bt2e
        opacity: 1;
        pointer-events: auto;
    }

    &_container {
        max-width: 1360px;
        margin: 95px auto 0;
        height: 110px;
        display: flex;
        justify-content: space-between;
        background-color: $sega-grey;
        box-shadow: 0px 16px 15px -7px rgba(0,0,0,0.45);

        &_title {
            min-width: 295px;
            width: 175%;
            margin-top: 28px;
            padding: 0 25px;
            text-align: center;
            border-right: 1px solid $sega-blue;
            margin-bottom: 20px;

            h3 {
                margin-top: 10px;
                text-transform: uppercase;
                font-size: 30px;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 800;
                color: $sega-blue;
            }

            .hide-button {
                display: none;
                content: '';
                width: 35px;
                height: fit-content;
                margin-top: 5px;
                position: absolute;
                right: 14px;
                border: none;
                transition: all 0.1s ease-in-out;
                cursor: pointer;

                &:after {
                    content: '»';
                    transform: rotate(90deg);
                    display: block;
                    font-size: 25px;
                    margin-left: 4px;
                }

                &:hover {
                    transition: all 0.2s ease-in-out;

                    &:after {
                        color: $sega-red;
                    }
                }

                &--hide {
                    transition: all 0.1s ease-in-out;
                    transform: rotate(180deg);

                    &:after {
                        color: $sega-red;
                    }
                }
            }
        }

        &_cost {
            text-align: center;
            border-right: 1px solid $sega-blue;
            width: 100%;
            margin-top: 28px;
            margin-bottom: 20px;
            padding: 0 25px;

            h2 {
                color: $sega-blue;
                font-size: 37px;
                margin: 0;
            }

            h3 {
                text-transform: uppercase;
                color: $sega-blue;
                font-size: 17px;
                width: 100%;
                margin-left: 10px;
                margin-top: -5px;
            }

            &--hide {
                display: none !important;
            }
        }

        &_quantity {
            text-align: center;
            border-right: 1px solid $sega-blue;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 0 25px;

            label {
                margin-top: 15px;
                color: $sega-blue;
                font-size: 18px;
                margin-right: 15px;;
            }

            input {
                margin-top: 24px;
                border: none;
                border-radius: 0;
                width: 48px;
                padding-left: 17px;
                height: 32px;
                box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.35);
            }

            &--hide {
                display: none !important;
            }
        }

        &_add {
            text-align: center;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 0 25px;

            .button_blue {
                background-color: $sega-blue;
                outline: none;
                border: 1px solid $sega-white;
                border-radius: 20px;
                color: $sega-white;
                width: 100%;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                text-transform: uppercase;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 600;
                padding: 12px;
                opacity: 1;

                &:hover {
                    transition: 0.2s ease-in-out;
                    opacity: 0.8;
                    background-color: $sega-red;

                    &:active {
                        transform: scale(0.95);
                    }
                }
            }

            .heart {
                margin-top: 8px !important;
                white-space: nowrap;
                @include buttonHeart;
            }

            &--hide {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 1280px) {

        &_container {
            margin: 110px auto 0;

            &_title {
                h3 {
                    font-size: 26px;
                }
            }

            &_cost {
                margin-top: 32px;

                h2 {
                    font-size: 32px;
                }

                h3 {
                    font-size: 14px;
                }
            }
        }
    }

    @media only screen and (max-width: 945px) {

        &_container {

            &_title {
                margin-top: 36px;
                padding: 0 10px;

                h3 {
                    font-size: 22px;
                }
            }

            &_cost {
                padding: 0 10px;
            }

            &_quantity {
                padding: 0 10px;
                display: inline-grid;
                justify-content: center;

                label {
                    margin-top: 5px;
                    margin-right: auto;
                }

                input {
                    margin: 10px auto 0 auto;
                }
            }
        }
    }
    @media only screen and (max-width: 820px) {

        &_container {
            display: block;
            height: auto;

            &_title {
                border-bottom: 1px solid $sega-blue;
                width: auto;
                padding-top: 12px;
                padding-bottom: 10px;
                margin-bottom: -40px;
                margin-top: 0px;
                height: auto;
                border-right: none;
                border-bottom: 1px solid $sega-blue;
                display: flex;
                justify-content: center;

                h3 {
                    font-size: 23px;
                    margin-bottom: 10px;
                }

                .hide-button {
                    display: block;
                }
            }

            &_cost {
                margin-bottom: -66px;
                margin-top: 5px;
                border-right: none;
                padding-top: 42px;

                h2 {
                    font-size: 28px;
                }

                h3 {
                    margin-top: 0;
                    font-size: 13px;
                    margin-left: 6px;
                }
            }

            &_quantity {
                margin-bottom: -40px;
                display: flow-root;
                margin-top: 70px;
                border: none;

                label {
                    font-size: 15px;
                }
            }

            &_add {
                margin-top: 50px;
                margin-bottom: 0;
                padding-bottom: 20px;
            }
        }
    }
    @media only screen and (max-width: 560px) {
        &_container {
            margin: 100px auto 0 auto;

            &_title h3 {
                font-size: 18px;
                margin: 0;
            }

            &_cost {
                margin-top: 0px;
                padding-top: 50px;

                h2 {
                    font-size: 20px;
                }
            }

            &_quantity input {
                margin: 0;
            }

            &_add {
                padding-bottom: 10px;

                .button_blue {
                    font-size: 12px;
                    padding: 8px;
                }
            }
        }
    }
}
