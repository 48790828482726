.sega__product.part-and-prize {
  .sega__product_container {
    justify-content: flex-start;
  }

  .sega__product_container_boxLeft {
    width: 445px;
  }

  .sega__product_container_boxRight {
    width: calc(100% - 445px);
  }

  .sega__product_container_boxLeft_galleryMin {
    max-width: 100%;
  }

  .sega__product_container_boxRight_head {
    margin-top: 10px;

    .above-title {
      font-size: 30px;
    }
  }

  .button-blue {
    font-size: 16px;
    padding: 10px 25px;
  }

  .sega__product_sliders.w100 {
    .sega__product_sliders_slider {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .sega__product.part-and-prize {
    .sega__product_container_boxLeft {
      width: 35%;
    }
  
    .sega__product_container_boxRight {
      width: 65%;
    }
  }
}

@media only screen and (max-width: 940px) {
  .sega__product.part-and-prize {
    .sega__product_container_boxLeft,
    .sega__product_container_boxRight {
      width: 100%;
    }

    .sega__product_container_boxRight_head .above-title {
      text-align: center;
    }
  }
}