@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__exhibitions {

    &_container {
        max-width: 1080px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &_box {
            max-width: 228px;
            margin: 50px 15px;

            &_image {
                width: 228px;
                height: 220px;

                img {
                    height: 100%!important;
                    object-fit: cover;
                    width: 100%;
                }

                &_bg {
                    position: relative;
                    background-color: rgba($sega-white, 0.8);

                    &_date {
                        padding-top: 5px;
                        display: block;
                        text-align: center;
                        margin-top: -70px;
                        font-size: 18px;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                        color: $sega-black;
                        text-transform: uppercase;
                    }

                    &_place {
                        padding-bottom: 5px;
                        text-align: center;
                        display: block;
                        font-size: 18px;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                        color: $sega-black;
                        text-transform: uppercase;
                    }
                }

            }

            &_title {
                font-size: 18px;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 900;
                margin-top: 15px;
                text-transform: uppercase;
            }

            &_date {
                color: $sega-black;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 900;
            }

            &_place {
                color: $sega-black;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 900;
                margin-bottom: 10px;
            }

            &_link,
            &_ics_calendar {

                a {
                    transition: all 0.2s ease-in-out;
                    display: block;
                    color: $sega-blue;

                    &:after {

                        content: '»';
                        color: $sega-blue;
                        font-size: 20px;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                        pointer-events: none;
                        transition: all 0.2s ease-in-out;
                        margin-left: auto;
                        align-items: center;
                        padding-left: 10px;
                        margin-block: auto;
                    }

                    &:hover {

                        color: $sega-red;
                        transition: all 0.2s ease-in-out;

                        &:after {

                            color: $sega-red;
                            transition: all 0.2s ease-in-out;
                        }
                    }
                }

            }
        }

        @media only screen and (max-width: 1020px) {
            padding: 0 20px;
        }
    }
}
