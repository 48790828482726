.section-cart {
    padding: 50px 0;

    .wrapper {
        max-width: 975px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .cart-header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        h1 {
            font-size: 2.4em;
            text-transform: uppercase;
            margin: 0;
        }
    }

    .item {
        align-items: center;
        background: $sega-white;
        box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: flex-start;
        margin: 20px 0;
        padding: 15px;

        .image {
            display: block;
            height: 100px;
            width: 100px;

            img {
                border: 1px solid #ddd;
                display: block;
                height: 100%;
                object-fit: contain;
                object-position: center;
                width: 100%;
            }
        }

        .name {
            color: $sega-black;
            padding: 10px;
            width: calc(65% - 260px);
        }

        .quantity {
            text-align: center;
            width: 80px;

            input {
                border: 1px solid #ddd;
                border-radius: 4px;
                display: block;
                margin-bottom: 8px;
                padding: 0;
                text-align: center;
                width: 100%;
            }

            .button-blue {
                font-size: 14px;
                padding: 2px 10px;
            }
        }

        .remove {
            text-align: center;
            text-transform: uppercase;
            width: 100px;

            a {
                text-align: center;

                img {
                    display: block;
                    height: auto;
                    margin: 0 auto 5px auto;
                    width: 12px;
                }
            }
        }

        .prices {
            width: 35%;

            .price {
                align-items: center;
                color: $sega-black;
                display: flex;
                font-size: 18px;
                text-transform: uppercase;
                width: 100%;

                small {
                    color: $sega-light-blue;
                    font-size: 9px;
                    padding: 0 5px;
                }

                .num {
                    display: block;
                    margin-left: auto;
                }

                &.total {
                    color: $sega-blue;
                    font-weight: 700;
                    margin-top: 5px;
                }
            }
        }
    }

    .promotional-code,
    .summary {
        margin-left: auto;
        width: 35%;
    }

    .promotional-code {
        input {
            border: 1px solid #ddd;
            border-radius: 4px;
            display: block;
            margin: 10px 0 14px 0;
            padding: 3px 10px;
            width: 100%;
        }

        .button-blue {
            font-size: 14px;
            padding: 2px 10px;
        }
    }

    .summary {
        margin-top: 30px;

        .summary-item {
            align-items: center;
            color: $sega-black;
            display: flex;
            margin: 5px 0;
            text-transform: uppercase;
            width: 100%;

            small {
                color: $sega-light-blue;
                font-size: 9px;
                padding: 0 5px;
            }

            .num {
                display: block;
                margin-left: auto;
            }

            &.total {
                border-top: 1px solid $sega-blue;
                color: $sega-blue;
                font-weight: 700;
                margin-top: 10px;
                padding-top: 10px;
            }

            &.delivery {
                text-align: right;
            }
        }

        .button-green {
            display: block;
            margin-top: 30px;
            margin-left: auto;
            width: 210px;
        }
    }

    &.empty {
        .wrapper {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
        }

        h1 {
            font-size: 1.3em;
            margin: 20px 0;
            text-transform: uppercase;
        }

        .icon {
            display: block;
            height: 40px;
            opacity: 0.5;
            width: 40px;
        }

        .image {
            display: block;
            height: auto;
            margin: 30px auto 0 auto;
            width: 300px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .section-cart {
        .item {
            flex-wrap: wrap;

            .image {
                margin-bottom: 10px;
            }

            .name {
                margin-bottom: 10px;
                width: calc(100% - 100px);
            }

            .prices {
                width: calc(100% - 180px);
            }
        }

        .promotional-code, 
        .summary {
            width: 50%;
        }
    }
}

@media only screen and (max-width: 560px) {
    .section-cart {
        .cart-header {
            flex-wrap: wrap;
        }
        
        .item {
            flex-wrap: wrap;

            .quantity {
                align-items: center;
                display: flex;
                width: calc(100% - 100px);

                input {
                    margin-bottom: 0;
                    margin-right: 10px
                }
            }

            .remove {
                width: 100px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }

            .prices {
                margin-top: 10px;
                width: 100%;
            }
        }

        .promotional-code, 
        .summary {
            width: 100%;
        }
    }
}