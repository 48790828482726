@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__cookie {
    display: none;
    border-bottom: 1px solid $sega-grey;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    font-size: 16px;
    padding: 20px 0;
    background: $sega-white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    z-index: 100;
    &_content {
        max-width: 1220px;
        margin: auto;
        &_container {
            margin: 0 10px;
            padding: 0 10px;

            .title-cookie {
                font-size: 28px;
                margin-bottom: 10px;
                text-transform: uppercase;
                color: $sega-blue;
                font-weight: 500;

                strong {
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 900;
                }
            }

            .text-cookie {
                color: $sega-blue;

                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .more-information-cookie {
                background: transparent;
                border: none;
                outline: none;
                margin: 10px 0;
                color: $sega-blue;
                padding: 0;
                font-family: 'futura-pt-bold', sans-serif;
                font-weight: 600;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                text-transform: uppercase;

                &:hover {
                    transition: all 0.2s ease-in-out;
                    color: $sega-red;
                }

                &:after {
                    content: '»';
                    font-size: 20px;
                }

                &--open {
                    color: $sega-red;
                    display: flex;

                    &:hover {
                        color: $sega-black;

                        &:after {
                            color: $sega-black;
                        }
                    }

                    &:after {
                        transform: rotate(90deg);
                        display: block;
                        margin-left: 4px;
                        margin-top: -2px;
                        color: $sega-red;
                    }
                }
            }

            &_explanation {
                display: none;
                padding: 20px 20px;
                border: 1px solid $sega-dark-grey;
                background: $sega-grey;
                color: $sega-blue;

                h3 {
                    margin-bottom: 5px;
                }

                p {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &--active {
                    display: block;
                }
            }

            &_settings {
                display: none;
                padding: 20px 20px;
                margin: 10px 0;
                border: 1px solid $sega-dark-grey;
                color: $sega-blue;
                @include segaCheckbox;
                h4 {
                    text-transform: uppercase;
                    font-size: 18px;
                    margin-bottom: 5px;
                }

                label {
                    display: block;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .checkMark {
                        margin-top: 1px;
                    }
                }

                .disable {
                    cursor: not-allowed;
                    filter: grayscale(1);
                    opacity: 0.3;
                }


                &--active {
                    display: block;
                }

                .container input:checked ~ .title {
                    color: $sega-blue
                }

                .container input:checked ~ .checkMark {
                    border: 2px solid $sega-blue;

                    &:after {
                        border: 2px solid $sega-blue;
                        border-width: 0 3px 3px 0;
                    }
                }
            }

            &_buttons {
                padding-top: 10px;
                display: flex;

                button {
                    width: 100%;
                    padding: 5px 40px;
                }

                .input__button_red {
                    margin-left: 10px;
                }

                .input__button_white {

                    button {
                        transition: all 0.2s ease-in-out;
                        display: flex;

                        &:after {
                            transition: all 0.2s ease-in-out;
                            content: '»';
                            font-size: 16px;
                            font-family: 'futura-pt-bold', sans-serif;
                            font-weight: 900;
                            margin-left: 4px;
                        }
                    }
                }

                .cookie-settings-button--open {
                    transition: all 0.2s ease-in-out;
                    filter: drop-shadow(2px 4px 6px rgba($sega-black, 0.2));

                    button {
                        transition: all 0.2s ease-in-out;
                        border-color: $sega-red;
                        color: $sega-red;

                        &:after {
                            transition: all 0.2s ease-in-out;
                            transform: rotate(-90deg);
                            display: block;
                            margin-top: 1px;
                        }
                    }
                }
            }
        }
    }

    &--hidden {
        display: none !important;
    }

    &--open {
        display: block;
    }

    @media only screen and (max-width: 980px) {

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            background-color: transparent !important;
            cursor: pointer;
            border-radius: 40px;
        }

        ::-webkit-scrollbar {
            width: 12px;
            background-color: transparent !important;
            border-radius: 40px;
            cursor: pointer;
        }

        ::-webkit-scrollbar-thumb {
            background-color: $sega-blue;
            cursor: pointer !important;
            border-radius: 40px;
        }

        &_content {
            max-height: 95vh;
            overflow-y: auto;

            &_container {

                .title-cookie {
                    font-size: 40px;
                }

                .text-cookie {
                    font-size: 20px;
                }

                .more-information-cookie {
                    font-size: 18px;
                }


                &_explanation {

                    h3 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 18px;
                    }
                }

                &_settings {

                    h4 {
                        font-size: 20px;
                    }

                    .title {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 475px) {

        &_content {

            &_container {
                text-align: center;

                .more-information-cookie {
                    margin: 10px auto;
                }

                &_buttons {
                    display: block;

                    button {
                        justify-content: center;
                    }

                    .input__button_red {
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
