@import "../../variables/colors";
@import "../../variables/keyframes";
@import "../../variables/mixins";

.sega__slider {

  &_content {
      width: 80%;
    @include segaSlider;

      .home-page-slider {

          .slick-list {

              .slick-track {

                  margin: 14px 0;
              }
          }
      }
  }

    @media only screen and (max-width: 600px) {

        &_content {
            padding: 0 15px;
        }
    }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0 20px;

  img {
    object-fit: contain;
    margin: auto;
    box-shadow: 0 0 12px -2px rgba(0,0,0,0.55);
  }
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  zoom: 1;
  height: 100%;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  & > a {
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, .2));
    display: block;
    margin: 0 auto;
    width: 221px;
  }

  img {
    box-shadow: none;
    display: block;
    background-color: $sega-white;

    &.slick-loading {
      padding-bottom: 100%;
    }
  }

  .title {
    background: #fff;
    color: #083a7e;
    display: block;
    font-size: 14px;
    font-family: 'futura-pt-bold', sans-serif;
    font-weight: 600;
    margin: 0 auto;
    min-height: 62px;
    padding: 15px 5px;
    text-align: center;
    text-transform: uppercase;
    width: 221px;
  }

  display: none;
  &.dragging img {
    pointer-events: none;
  }
  .slick-initialized & {
    display: block;
  }
  .slick-loading & {
    visibility: hidden;
  }
  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-track.products-slider {
  .slick-slide > a {
    width: auto;
  }
}
/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
  &.slick-disabled:before {
    opacity: 0.25;
  }
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
  top: 40%;
  transition: 0.2s ease-in-out;

  &:before {
    color: $sega-blue;
    content: '»';
      font-weight: 100;
    font-size: 61px;
    transform: rotate(180deg);
    display: inherit;
    transition: 0.2s ease-in-out;
  }

    &:hover {

        &:before {
            transition: 0.2s ease-in-out;
            color: $sega-red;
        }
    }
}

.slick-next {
  right: -25px;
  top: 35%;
  transition: 0.2s ease-in-out;

  &:before {
    color: $sega-blue;
    content: '»';
      font-weight: 100;
    font-size: 61px;
    transition: 0.2s ease-in-out;
  }

    &:hover {

        &:before {
            transition: 0.2s ease-in-out;
            color: $sega-red;
        }
    }
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0px;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0px 5px;
    padding: 0px;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      outline: none;

      &:focus {
        outline: none;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '\2022';
        width: 20px;
        height: 20px;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      opacity: 0.75;
    }
  }
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0px;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0px 5px;
    padding: 0px;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      outline: none;

      &:focus {
        outline: none;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '\2022';
        width: 20px;
        height: 20px;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: white;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      opacity: 0.75;
    }
  }

}

.slick-slider {
  touch-action: pan-y!important;
  -ms-touch-action: pan-y!important;
}
