@import "../variables/colors";
@import "../fonts/fonts";

html {
  scroll-behavior: smooth;
}

.scroll-with-offset {
  padding-top: 100px;
  margin-bottom: -100px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0px 0px, 0px 0px,0px 0px, 0px 0px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px!important;
    overflow: hidden;

    &:focus {
        clip: auto !important;
        display: block;
        height: auto;
        left: 5px;
        top: 5px;
        width: auto;
        z-index: 100000;
    }
}

body {
    font-family: 'futura-pt', sans-serif;
    padding: 0;
    margin: 0;

    &.no-scroll {
        overflow: hidden;
    }

    &.lg-on {
        .sega__header-wrapper {
            z-index: 0;
        }
    }

    main {
        background-color: $sega-grey;
        color: $sega-blue;
        overflow: hidden;
        padding: 1px 0;

        section {
            margin: 0 auto;
            max-width: 1360px;

            &.sega__welcome,
            &.sega__prizeAndParts,
            &.type-width_100  {
                max-width: 100%;
            }
        }
    }

    footer, header {
        margin: 0 auto;
        color: $sega-blue;
        background-color: $sega-grey;
    }

    footer {
        background-color: $sega-white;
        display: grid;
        margin-bottom: 100px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding-inline-start: 0;
    }

    a {
        text-decoration: none;
        color: $sega-blue;
        cursor: pointer;
    }

    p {
        margin: 0;
    }

    h3, h4 {
        display: block;
        margin: 0;
        font-family: 'futura-pt-bold', sans-serif;
        font-weight: bold;
    }

    h3 {
        font-size: 18px;
    }

    button, input, select {
        border-radius: 20px;
        outline: none;
    }

    select {
        border-radius: 5px;
        outline: none;
    }

    button, input, optgroup, select, textarea {
        font-size: 16px;
    }

    input[type="date"] {
        appearance: none;
        background: $sega-white;
        color: $sega-black;
        line-height: 34px;
    }

    img, a, video {

        &:focus {
            outline: none;
        }
    }

    img {
        max-width: 100%;
        height: auto!important;
    }

    video {
        cursor: pointer;
    }

    input {
        border: none;
        padding-left: 55px;
    }

    .border-line-head, .border-line {
        display: block;
        width: 100%;
        margin: 15px 0;
    }

    .border-line-head {
        border-top: 2px solid;
        border-color: rgba($sega-blue, 0.2);
    }

    .border-line {
        border-top: 1px solid;
        border-color: $sega-blue;
    }

    .margin-bottom-box {
        padding-bottom: 40px;
    }

    .not-found {
        padding-top: 200px;
        text-align: center;
        padding-bottom: 100px;

        .wrapper {
            padding: 0 20px;

            h1 {
                font-size: 60px;
            }

            strong {
                font-size: 23px;
            }
        }
    }

    .login-subpage {
        padding: 50px 0;
        text-align: center;

        .wrapper {
            padding: 0 20px;

            h2 {
                font-size: 40px;
                margin: 0 0 20px 0;
            }
        }
    }

    .recently-view-list {
        .recently-box {
            display: inline-block;

            a {

                img {
                    background: $sega-white;
                    border: 1px solid $sega-dark-grey;
                    width: 90px;
                    height: 90px!important;
                    object-fit: contain;
                }
            }

        }
    }

    .product_responsiveRight {

        .slick-list {

            .slick-track {
                margin-top: 0;

                img {
                    background: $sega-white;
                    border: 1px solid $sega-dark-grey;
                    width: 90px;
                    height: 90px!important;
                    object-fit: contain;
                }
            }
        }
    }

    .lg-object {
        background-color: $sega-white;
    }

    .display-games {
        display: flex;
        flex-wrap: wrap;
    }

    .searching {
        margin: 0 auto;
        position: relative;

        .sega__productsBasket_content_products {
            display: none;
        }

        .sega__productsTab_content_products_product {
            display: none;
        }

        h3 {
            opacity: 0;
        }

        .display-games {
            opacity: 0.35;
        }

        .searching-ellipsis {
            display: block !important;
        }
    }
}

.error-validation {
    color: $sega-red;
    display: block;
    font-size: 14px;
    width: 100%;
}

.slick-slider {
    touch-action: pan-y!important;
    -ms-touch-action: pan-y!important;
}

.image-padding-l-10 {
    padding-left: 10px
}

.image-padding-r-10 {
    padding-right: 10px
}

.image-padding-l-15 {
    padding-left: 15px
}

.image-padding-r-15 {
    padding-right: 15px
}

.image-padding-l-20 {
    padding-left: 20px
}

.image-padding-r-20 {
    padding-right: 20px
}

.image-padding-l-25 {
    padding-left: 25px
}

.image-padding-r-25 {
    padding-right: 25px
}

.select-single {
    .select2.select2-container {
        appearance: none;
        background: $sega-white;
        border: none;
        border-radius: 5px;
        box-shadow: 0 0 15px 2px rgba(0,0,0,.1);
        cursor: pointer;
        font-size: 19px;
        height: 37px;
        position: relative;
        outline: none;
        width: 100%!important;

        &.select2-container--default.select2-container--open {
            width: auto;
        }

        .select2-selection--single {
            background: none;
            border: none;
            height: 37px;
            outline: none;
            width: 100%;

            .select2-selection__rendered {
                border: none;
                border-radius: 5px;
                color: $sega-blue;
                display: flex;
                font-size: 16px;
                font-weight: 900;
                height: 37px;
                margin: 0;
                padding: 5px 15px;
                text-transform: uppercase;

                .select2-selection__choice {
                    background: none;
                    border: none;
                }

                li {
                    line-height: 1.5;
                }
            }

            .select2-selection__clear {
                color: $sega-black;
                margin-right: 5px;
            }

            &[aria-expanded="true"] {
                .select2-selection__arrow:after {
                    right: 18px;
                    top: -6px;
                    transform: rotate(270deg);
                }
            }
        }

        .select2-selection__arrow {
            top: 5px;
            width: 30px;

            &:after {
                content: '»';
                color: #083a7e;
                display: block;
                font-size: 36px;
                font-weight: 100;
                pointer-events: none;
                position: absolute;
                right: 8px;
                top: -7px;
                transform: rotate(90deg);
                transition: all .15s ease-in-out;
            }

            b {
                border: none;
            }
        }

        .select2-result {
            &-empty {
                color: $sega-dark-grey;
            }
        }
    }

    .select2-dropdown.select2-dropdown--below {
        border: 1px solid $sega-grey;
        border-radius: 5px;
        box-shadow: 0 0 15px 2px rgba(0,0,0,.1);
        top: 5px;

        .select2-dropdown {
            background-color: $sega-white;
            border: none;
            border-radius: 0;
            color: $sega-blue;
            top: 0px;
            width: 100%;

            .select2-results,
            .select2-results__options {
                width: 100%;
            }
        }

        .select2-results__options {
            border-radius: 0 0 5px 5px;
            position: relative;
        }

        .select2-results__option {
            color: $sega-blue;
            padding: 10px 15px;
            position: relative;

            &.select2-results__option--selected {
                font-weight: 900;
            }
        }

        .select2-results__option[aria-selected=true] {
            background-color: $sega-red;
            color: $sega-white;
        }
    }
}

@media only screen and (max-width: 850px) {
    body footer {
        margin-bottom: 40px;
    }
}
