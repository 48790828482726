@import "../variables/colors";

.sega__footer {
    &_top {
        background-color: $sega-blue;

        &_box {
            max-width: 975px;
            margin: auto;
            display: flex;

            &_follow {
                text-transform: uppercase;
                color: $sega-white;
                margin: 60px 0;
                width: max-content;
                padding-right: 45px;
                border-right: 1px solid $sega-white;

                h3 {
                    font-size: 74px;
                }

                a {
                    color: $sega-white;
                    transition: 0.2s ease-in-out;

                    h4 {
                        font-size: 28px;
                        margin-bottom: 10px;
                    }

                    &:hover {
                        transition: 0.2s ease-in-out;
                        color: rgba($sega-red, 0.8);
                    }
                }

                &_social {
                    white-space: nowrap;
                }
            }

            &_form {
                width: 100%;
                margin-left: 45px;

                h3 {
                    color: $sega-white;
                    font-size: 36px;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 900;
                    margin-bottom: 15px;
                    margin-top: 30px;
                    text-transform: uppercase;
                }

                &_inputs {
                    input {
                        font-size: 16px;
                        padding: 10px 10px 10px 30px;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                }

                .input__button_red button {
                    border: 1px solid $sega-white;
                    text-transform: uppercase;
                    font-size: 20px;
                    font-family: 'futura-pt-bold', sans-serif;
                    font-weight: 900;
                    max-width: 300px;
                }
            }
        }
    }

    &_bottom {
        background-color: $sega-white;
        color: $sega-black;
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        font-family: 'futura-pt-bold', sans-serif;
        font-weight: 600;
        max-width: 1165px;
        margin: 70px auto auto auto;

        a {
            color: $sega-black;
            transition: 0.2s ease-in-out;

            &:hover {
                transition: 0.2s ease-in-out;
                color: rgba($sega-red, 0.8);
                text-decoration: underline;
            }
        }

        &_contact {
            font-style: normal;
            font-weight: 200;
            padding-right: 20px;
            width: calc(100% / 6);

            &_mail {
                display: block;
                margin: 10px 0;
                transition: 0.2s ease-in-out;
                color: $sega-black;

                &:hover {
                    transition: 0.2s ease-in-out;
                    color: rgba($sega-red, 0.8);
                    text-decoration: underline;
                }
            }

            &_phone {
                display: block;
                margin: 10px 0;
                transition: 0.2s ease-in-out;
                color: $sega-black;

                &:hover {
                    transition: 0.2s ease-in-out;
                    color: rgba($sega-red, 0.8);
                    text-decoration: underline;
                }
            }
        }

        &_menu {
            font-weight: 200;
            width: calc((100% / 6) * 3);

            ul {
                display: flex;
                flex-wrap: wrap;
                line-height: 18px;

                li {
                    white-space: nowrap;
                    cursor: pointer;
                    line-height: 1.1;
                    width: calc(100% / 3);

                    a {
                        color: $sega-black;
                        transition: 0.2s ease-in-out;

                        &:hover {
                            transition: 0.2s ease-in-out;
                            color: rgba($sega-red, 0.8);
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &_privacy {
            font-weight: 200;
            margin-top: 20px;
            width: 100%;

            a {
                color: $sega-black;
                display: inline-block;
                transition: 0.2s ease-in-out;

                &:hover {
                    transition: 0.2s ease-in-out;
                    color: rgba($sega-red, 0.8);
                    text-decoration: underline;
                }
            }

            .text-mid {
                margin-top: 10px;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;

                li {
                    margin-top: 10px;
                    display: flex;
                    cursor: pointer;
                    margin-left: 2px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:after {
                        content: '/';
                        display: block;
                        margin-top: 0;
                    }
                }
            }
        }

        &_about {
            font-weight: 200;
            width: calc(100% / 6);

            p:nth-child(3) {
                margin-bottom: -15px;
                ;
            }

            p {
                margin-bottom: 15px;
            }

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    display: flex;
                    margin-right: 5px;
                    width: 100%;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        color: $sega-black;
                        transition: 0.2s ease-in-out;

                        &:hover {
                            transition: 0.2s ease-in-out;
                            color: $sega-red;
                            text-decoration: underline;
                        }
                    }
                }
            }

            &_follow {
                display: flex;
                color: $sega-blue;
                margin-top: 20px;

                &_box {
                    margin-top: 25px;

                    h3 {
                        text-transform: uppercase;
                        font-size: 26px;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-weight: 900;
                    }

                    a {
                        font-weight: 900;
                        font-family: 'futura-pt-bold', sans-serif;
                        font-size: 13px;
                    }
                }


                &_social {
                    margin-top: 20px;
                }
            }
        }
    }

    @media only screen and (max-width: 1175px) {

        &_top {

            &_box {
                display: block;

                &_follow {
                    margin: auto;
                    border-right: none;
                    border-bottom: 1px solid;
                    padding-right: 0;
                    padding-bottom: 45px;
                    padding-top: 20px;
                    width: auto;
                    text-align: center;
                }

                &_form {
                    width: auto;
                    margin-left: 0;
                    text-align: center;
                    padding-bottom: 40px;

                    h3 {
                        margin-top: 35px;
                    }

                    &_inputs {

                        input {
                            width: 46%
                        }
                    }

                    .input__button_red {
                        button {
                            width: 46%;
                        }
                    }
                }
            }
        }

        &_bottom {
            margin: 40px auto -15px auto;
            padding: 0 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &_menu {
                width: 50%
            }
        }
    }

    @media only screen and (max-width: 999px) {
        &_bottom {
            &_contact {
                margin: 20px 0;

                &_mail {
                    margin-bottom: 20px;
                    margin-top: 20px;
                }

                &_phone {
                    margin-bottom: 20px;
                }
            }

            &_menu {
                margin: 20px 0;

                li {
                    margin-bottom: 8px;
                }
            }

            &_about {
                border-top: 1px solid;
                border-bottom: 1px solid;
                padding: 20px 0;
                margin-top: 20px;
                margin-left: 0;
                width: 100%;

                &_follow {
                    justify-content: center;
                }
            }

            &_privacy {
                margin: 20px 10px;
                width: 100%;

                p {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @media only screen and (max-width: 850px) {
        &_top {
            &_box {
                &_form {
                    h3 {
                        font-size: 25px;
                    }
                }
            }
        }

        &_bottom {
            a {
                font-size: 1rem;
            }

            li {
                margin-top: 20px;
            }

            &_about {
                text-align: center;

                ul {
                    margin-top: 40px;
                    justify-content: center;
                    display: block;

                    li {
                        justify-content: center;
                        margin-top: 25px;

                        &:after {
                            display: none;
                        }
                    }
                }

                &_follow {
                    display: block;

                }
            }

            &_contact {
                font-size: 14px;
                margin-top: -20px;
                text-align: center;
                border-bottom: 1px solid;
                padding-bottom: 20px;
                width: 100%;
                max-width: 100%;
            }

            &_menu {
                text-align: center;
                width: 100%;
                max-width: 100%;
                margin: 0;

                a {
                    font-size: 14px;
                }
            }

            &_about {
                border-bottom: 0;
                font-size: 14px;
                order: 1;
            }

            &_privacy {
                border-top: 1px solid;
                text-align: center;
                font-size: 14px;
                padding-top: 20px;

                ul {
                    justify-content: center;
                    display: block;

                    li {
                        justify-content: center;
                        margin-top: 25px;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 720px) {
        .sega__footer_bottom_menu ul li {
            margin: 10px 0;
            padding: 0 10px;
            width: calc(100% / 3);
        }
    }

    @media only screen and (max-width: 600px) {
        &_top {

            &_box {

                &_form {

                    &_inputs {

                        input {
                            width: 90%
                        }
                    }

                    .input__button_red {
                        button {
                            width: 90%
                        }
                    }
                }
            }
        }

        .sega__footer_bottom_menu ul li {
            width: 50%;
        }
    }

    @media only screen and (max-width: 514px) {
        &_top {

            &_box {

                &_follow {

                    h3 {
                        font-size: 56px;
                    }

                    a {

                        h4 {
                            font-size: 21px;
                        }
                    }

                    &_social {

                        a {

                            img {
                                width: 35px;
                            }
                        }
                    }
                }

                &_form {

                    h3 {
                        padding: 0 15px;
                    }
                }
            }
        }
    }
}
